import { __awaiter, __generator } from "tslib";
import { HttpRequest } from "@aws-sdk/protocol-http";
import { isThrottlingError } from "@aws-sdk/service-error-classification";
import { v4 } from "uuid";
import { DEFAULT_RETRY_DELAY_BASE, INITIAL_RETRY_TOKENS, INVOCATION_ID_HEADER, REQUEST_HEADER, THROTTLING_RETRY_DELAY_BASE } from "./constants";
import { getDefaultRetryQuota } from "./defaultRetryQuota";
import { defaultDelayDecider } from "./delayDecider";
import { defaultRetryDecider } from "./retryDecider";
/**
 * The default value for how many HTTP requests an SDK should make for a
 * single SDK operation invocation before giving up
 */
export var DEFAULT_MAX_ATTEMPTS = 3;
/**
 * The default retry algorithm to use.
 */
export var DEFAULT_RETRY_MODE = "standard";
var StandardRetryStrategy = /** @class */function () {
  function StandardRetryStrategy(maxAttemptsProvider, options) {
    var _a, _b, _c;
    this.maxAttemptsProvider = maxAttemptsProvider;
    this.mode = DEFAULT_RETRY_MODE;
    this.retryDecider = (_a = options === null || options === void 0 ? void 0 : options.retryDecider) !== null && _a !== void 0 ? _a : defaultRetryDecider;
    this.delayDecider = (_b = options === null || options === void 0 ? void 0 : options.delayDecider) !== null && _b !== void 0 ? _b : defaultDelayDecider;
    this.retryQuota = (_c = options === null || options === void 0 ? void 0 : options.retryQuota) !== null && _c !== void 0 ? _c : getDefaultRetryQuota(INITIAL_RETRY_TOKENS);
  }
  StandardRetryStrategy.prototype.shouldRetry = function (error, attempts, maxAttempts) {
    return attempts < maxAttempts && this.retryDecider(error) && this.retryQuota.hasRetryTokens(error);
  };
  StandardRetryStrategy.prototype.getMaxAttempts = function () {
    return __awaiter(this, void 0, void 0, function () {
      var maxAttempts, error_1;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            _a.trys.push([0, 2,, 3]);
            return [4 /*yield*/, this.maxAttemptsProvider()];
          case 1:
            maxAttempts = _a.sent();
            return [3 /*break*/, 3];
          case 2:
            error_1 = _a.sent();
            maxAttempts = DEFAULT_MAX_ATTEMPTS;
            return [3 /*break*/, 3];
          case 3:
            return [2 /*return*/, maxAttempts];
        }
      });
    });
  };
  StandardRetryStrategy.prototype.retry = function (next, args) {
    return __awaiter(this, void 0, void 0, function () {
      var retryTokenAmount, attempts, totalDelay, maxAttempts, request, _loop_1, this_1, state_1;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            attempts = 0;
            totalDelay = 0;
            return [4 /*yield*/, this.getMaxAttempts()];
          case 1:
            maxAttempts = _a.sent();
            request = args.request;
            if (HttpRequest.isInstance(request)) {
              request.headers[INVOCATION_ID_HEADER] = v4();
            }
            _loop_1 = function () {
              var _a, response, output, err_1, delay_1;
              return __generator(this, function (_b) {
                switch (_b.label) {
                  case 0:
                    _b.trys.push([0, 2,, 5]);
                    if (HttpRequest.isInstance(request)) {
                      request.headers[REQUEST_HEADER] = "attempt=" + (attempts + 1) + "; max=" + maxAttempts;
                    }
                    return [4 /*yield*/, next(args)];
                  case 1:
                    _a = _b.sent(), response = _a.response, output = _a.output;
                    this_1.retryQuota.releaseRetryTokens(retryTokenAmount);
                    output.$metadata.attempts = attempts + 1;
                    output.$metadata.totalRetryDelay = totalDelay;
                    return [2 /*return*/, {
                      value: {
                        response: response,
                        output: output
                      }
                    }];
                  case 2:
                    err_1 = _b.sent();
                    attempts++;
                    if (!this_1.shouldRetry(err_1, attempts, maxAttempts)) return [3 /*break*/, 4];
                    retryTokenAmount = this_1.retryQuota.retrieveRetryTokens(err_1);
                    delay_1 = this_1.delayDecider(isThrottlingError(err_1) ? THROTTLING_RETRY_DELAY_BASE : DEFAULT_RETRY_DELAY_BASE, attempts);
                    totalDelay += delay_1;
                    return [4 /*yield*/, new Promise(function (resolve) {
                      return setTimeout(resolve, delay_1);
                    })];
                  case 3:
                    _b.sent();
                    return [2 /*return*/, "continue"];
                  case 4:
                    if (!err_1.$metadata) {
                      err_1.$metadata = {};
                    }
                    err_1.$metadata.attempts = attempts;
                    err_1.$metadata.totalRetryDelay = totalDelay;
                    throw err_1;
                  case 5:
                    return [2 /*return*/];
                }
              });
            };
            this_1 = this;
            _a.label = 2;
          case 2:
            if (!true) return [3 /*break*/, 4];
            return [5 /*yield**/, _loop_1()];
          case 3:
            state_1 = _a.sent();
            if (typeof state_1 === "object") return [2 /*return*/, state_1.value];
            return [3 /*break*/, 2];
          case 4:
            return [2 /*return*/];
        }
      });
    });
  };
  return StandardRetryStrategy;
}();
export { StandardRetryStrategy };
