import { __assign, __read, __spread, __values } from "tslib";
export var constructStack = function () {
  var absoluteEntries = [];
  var relativeEntries = [];
  var entriesNameSet = new Set();
  var sort = function (entries) {
    return entries.sort(function (a, b) {
      return stepWeights[b.step] - stepWeights[a.step] || priorityWeights[b.priority || "normal"] - priorityWeights[a.priority || "normal"];
    });
  };
  var removeByName = function (toRemove) {
    var isRemoved = false;
    var filterCb = function (entry) {
      if (entry.name && entry.name === toRemove) {
        isRemoved = true;
        entriesNameSet.delete(toRemove);
        return false;
      }
      return true;
    };
    absoluteEntries = absoluteEntries.filter(filterCb);
    relativeEntries = relativeEntries.filter(filterCb);
    return isRemoved;
  };
  var removeByReference = function (toRemove) {
    var isRemoved = false;
    var filterCb = function (entry) {
      if (entry.middleware === toRemove) {
        isRemoved = true;
        if (entry.name) entriesNameSet.delete(entry.name);
        return false;
      }
      return true;
    };
    absoluteEntries = absoluteEntries.filter(filterCb);
    relativeEntries = relativeEntries.filter(filterCb);
    return isRemoved;
  };
  var cloneTo = function (toStack) {
    absoluteEntries.forEach(function (entry) {
      //@ts-ignore
      toStack.add(entry.middleware, __assign({}, entry));
    });
    relativeEntries.forEach(function (entry) {
      //@ts-ignore
      toStack.addRelativeTo(entry.middleware, __assign({}, entry));
    });
    return toStack;
  };
  var expandRelativeMiddlewareList = function (from) {
    var expandedMiddlewareList = [];
    from.before.forEach(function (entry) {
      if (entry.before.length === 0 && entry.after.length === 0) {
        expandedMiddlewareList.push(entry);
      } else {
        expandedMiddlewareList.push.apply(expandedMiddlewareList, __spread(expandRelativeMiddlewareList(entry)));
      }
    });
    expandedMiddlewareList.push(from);
    from.after.reverse().forEach(function (entry) {
      if (entry.before.length === 0 && entry.after.length === 0) {
        expandedMiddlewareList.push(entry);
      } else {
        expandedMiddlewareList.push.apply(expandedMiddlewareList, __spread(expandRelativeMiddlewareList(entry)));
      }
    });
    return expandedMiddlewareList;
  };
  /**
   * Get a final list of middleware in the order of being executed in the resolved handler.
   */
  var getMiddlewareList = function () {
    var normalizedAbsoluteEntries = [];
    var normalizedRelativeEntries = [];
    var normalizedEntriesNameMap = {};
    absoluteEntries.forEach(function (entry) {
      var normalizedEntry = __assign(__assign({}, entry), {
        before: [],
        after: []
      });
      if (normalizedEntry.name) normalizedEntriesNameMap[normalizedEntry.name] = normalizedEntry;
      normalizedAbsoluteEntries.push(normalizedEntry);
    });
    relativeEntries.forEach(function (entry) {
      var normalizedEntry = __assign(__assign({}, entry), {
        before: [],
        after: []
      });
      if (normalizedEntry.name) normalizedEntriesNameMap[normalizedEntry.name] = normalizedEntry;
      normalizedRelativeEntries.push(normalizedEntry);
    });
    normalizedRelativeEntries.forEach(function (entry) {
      if (entry.toMiddleware) {
        var toMiddleware = normalizedEntriesNameMap[entry.toMiddleware];
        if (toMiddleware === undefined) {
          throw new Error(entry.toMiddleware + " is not found when adding " + (entry.name || "anonymous") + " middleware " + entry.relation + " " + entry.toMiddleware);
        }
        if (entry.relation === "after") {
          toMiddleware.after.push(entry);
        }
        if (entry.relation === "before") {
          toMiddleware.before.push(entry);
        }
      }
    });
    var mainChain = sort(normalizedAbsoluteEntries).map(expandRelativeMiddlewareList).reduce(function (wholeList, expendedMiddlewareList) {
      // TODO: Replace it with Array.flat();
      wholeList.push.apply(wholeList, __spread(expendedMiddlewareList));
      return wholeList;
    }, []);
    return mainChain.map(function (entry) {
      return entry.middleware;
    });
  };
  var stack = {
    add: function (middleware, options) {
      if (options === void 0) {
        options = {};
      }
      var name = options.name,
        override = options.override;
      var entry = __assign({
        step: "initialize",
        priority: "normal",
        middleware: middleware
      }, options);
      if (name) {
        if (entriesNameSet.has(name)) {
          if (!override) throw new Error("Duplicate middleware name '" + name + "'");
          var toOverrideIndex = absoluteEntries.findIndex(function (entry) {
            return entry.name === name;
          });
          var toOverride = absoluteEntries[toOverrideIndex];
          if (toOverride.step !== entry.step || toOverride.priority !== entry.priority) {
            throw new Error("\"" + name + "\" middleware with " + toOverride.priority + " priority in " + toOverride.step + " step cannot be " + ("overridden by same-name middleware with " + entry.priority + " priority in " + entry.step + " step."));
          }
          absoluteEntries.splice(toOverrideIndex, 1);
        }
        entriesNameSet.add(name);
      }
      absoluteEntries.push(entry);
    },
    addRelativeTo: function (middleware, options) {
      var name = options.name,
        override = options.override;
      var entry = __assign({
        middleware: middleware
      }, options);
      if (name) {
        if (entriesNameSet.has(name)) {
          if (!override) throw new Error("Duplicate middleware name '" + name + "'");
          var toOverrideIndex = relativeEntries.findIndex(function (entry) {
            return entry.name === name;
          });
          var toOverride = relativeEntries[toOverrideIndex];
          if (toOverride.toMiddleware !== entry.toMiddleware || toOverride.relation !== entry.relation) {
            throw new Error("\"" + name + "\" middleware " + toOverride.relation + " \"" + toOverride.toMiddleware + "\" middleware cannot be overridden " + ("by same-name middleware " + entry.relation + " \"" + entry.toMiddleware + "\" middleware."));
          }
          relativeEntries.splice(toOverrideIndex, 1);
        }
        entriesNameSet.add(name);
      }
      relativeEntries.push(entry);
    },
    clone: function () {
      return cloneTo(constructStack());
    },
    use: function (plugin) {
      plugin.applyToStack(stack);
    },
    remove: function (toRemove) {
      if (typeof toRemove === "string") return removeByName(toRemove);else return removeByReference(toRemove);
    },
    removeByTag: function (toRemove) {
      var isRemoved = false;
      var filterCb = function (entry) {
        var tags = entry.tags,
          name = entry.name;
        if (tags && tags.includes(toRemove)) {
          if (name) entriesNameSet.delete(name);
          isRemoved = true;
          return false;
        }
        return true;
      };
      absoluteEntries = absoluteEntries.filter(filterCb);
      relativeEntries = relativeEntries.filter(filterCb);
      return isRemoved;
    },
    concat: function (from) {
      var cloned = cloneTo(constructStack());
      cloned.use(from);
      return cloned;
    },
    applyToStack: cloneTo,
    resolve: function (handler, context) {
      var e_1, _a;
      try {
        for (var _b = __values(getMiddlewareList().reverse()), _c = _b.next(); !_c.done; _c = _b.next()) {
          var middleware = _c.value;
          handler = middleware(handler, context);
        }
      } catch (e_1_1) {
        e_1 = {
          error: e_1_1
        };
      } finally {
        try {
          if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
        } finally {
          if (e_1) throw e_1.error;
        }
      }
      return handler;
    }
  };
  return stack;
};
var stepWeights = {
  initialize: 5,
  serialize: 4,
  build: 3,
  finalizeRequest: 2,
  deserialize: 1
};
var priorityWeights = {
  high: 3,
  normal: 2,
  low: 1
};
