import { __assign, __awaiter, __generator } from "tslib";
export var resolveEndpointsConfig = function (input) {
  var _a;
  return __assign(__assign({}, input), {
    tls: (_a = input.tls) !== null && _a !== void 0 ? _a : true,
    endpoint: input.endpoint ? normalizeEndpoint(input) : function () {
      return getEndPointFromRegion(input);
    },
    isCustomEndpoint: input.endpoint ? true : false
  });
};
var normalizeEndpoint = function (input) {
  var endpoint = input.endpoint,
    urlParser = input.urlParser;
  if (typeof endpoint === "string") {
    var promisified_1 = Promise.resolve(urlParser(endpoint));
    return function () {
      return promisified_1;
    };
  } else if (typeof endpoint === "object") {
    var promisified_2 = Promise.resolve(endpoint);
    return function () {
      return promisified_2;
    };
  }
  return endpoint;
};
var getEndPointFromRegion = function (input) {
  return __awaiter(void 0, void 0, void 0, function () {
    var _a, tls, region, dnsHostRegex, hostname;
    var _b;
    return __generator(this, function (_c) {
      switch (_c.label) {
        case 0:
          _a = input.tls, tls = _a === void 0 ? true : _a;
          return [4 /*yield*/, input.region()];
        case 1:
          region = _c.sent();
          dnsHostRegex = new RegExp(/^([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9-]{0,61}[a-zA-Z0-9])$/);
          if (!dnsHostRegex.test(region)) {
            throw new Error("Invalid region in client config");
          }
          return [4 /*yield*/, input.regionInfoProvider(region)];
        case 2:
          hostname = ((_b = _c.sent()) !== null && _b !== void 0 ? _b : {}).hostname;
          if (!hostname) {
            throw new Error("Cannot resolve hostname from client config");
          }
          return [2 /*return*/, input.urlParser((tls ? "https:" : "http:") + "//" + hostname)];
      }
    });
  });
};
