import { __awaiter, __generator, __values } from "tslib";
import { isArrayBuffer } from "@aws-sdk/is-array-buffer";
import { toHex } from "@aws-sdk/util-hex-encoding";
import { SHA256_HEADER, UNSIGNED_PAYLOAD } from "./constants";
/**
 * @internal
 */
export function getPayloadHash(_a, hashConstructor) {
  var headers = _a.headers,
    body = _a.body;
  return __awaiter(this, void 0, void 0, function () {
    var _b, _c, headerName, hashCtor, _d;
    var e_1, _e;
    return __generator(this, function (_f) {
      switch (_f.label) {
        case 0:
          try {
            for (_b = __values(Object.keys(headers)), _c = _b.next(); !_c.done; _c = _b.next()) {
              headerName = _c.value;
              if (headerName.toLowerCase() === SHA256_HEADER) {
                return [2 /*return*/, headers[headerName]];
              }
            }
          } catch (e_1_1) {
            e_1 = {
              error: e_1_1
            };
          } finally {
            try {
              if (_c && !_c.done && (_e = _b.return)) _e.call(_b);
            } finally {
              if (e_1) throw e_1.error;
            }
          }
          if (!(body == undefined)) return [3 /*break*/, 1];
          return [2 /*return*/, "e3b0c44298fc1c149afbf4c8996fb92427ae41e4649b934ca495991b7852b855"];
        case 1:
          if (!(typeof body === "string" || ArrayBuffer.isView(body) || isArrayBuffer(body))) return [3 /*break*/, 3];
          hashCtor = new hashConstructor();
          hashCtor.update(body);
          _d = toHex;
          return [4 /*yield*/, hashCtor.digest()];
        case 2:
          return [2 /*return*/, _d.apply(void 0, [_f.sent()])];
        case 3:
          // As any defined body that is not a string or binary data is a stream, this
          // body is unsignable. Attempt to send the request with an unsigned payload,
          // which may or may not be accepted by the service.
          return [2 /*return*/, UNSIGNED_PAYLOAD];
      }
    });
  });
}
