import { __assign, __awaiter, __generator, __read } from "tslib";
import { SignatureV4 } from "@aws-sdk/signature-v4";
export function resolveAwsAuthConfig(input) {
  var _this = this;
  var credentials = input.credentials || input.credentialDefaultProvider(input);
  var normalizedCreds = normalizeProvider(credentials);
  var _a = input.signingEscapePath,
    signingEscapePath = _a === void 0 ? true : _a,
    _b = input.systemClockOffset,
    systemClockOffset = _b === void 0 ? input.systemClockOffset || 0 : _b,
    sha256 = input.sha256;
  var signer;
  if (input.signer) {
    //if signer is supplied by user, normalize it to a function returning a promise for signer.
    signer = normalizeProvider(input.signer);
  } else {
    //construct a provider inferring signing from region.
    signer = function () {
      return normalizeProvider(input.region)().then(function (region) {
        return __awaiter(_this, void 0, void 0, function () {
          return __generator(this, function (_a) {
            switch (_a.label) {
              case 0:
                return [4 /*yield*/, input.regionInfoProvider(region)];
              case 1:
                return [2 /*return*/, [_a.sent() || {}, region]];
            }
          });
        });
      }).then(function (_a) {
        var _b = __read(_a, 2),
          regionInfo = _b[0],
          region = _b[1];
        var signingRegion = regionInfo.signingRegion,
          signingService = regionInfo.signingService;
        //update client's singing region and signing service config if they are resolved.
        //signing region resolving order: user supplied signingRegion -> endpoints.json inferred region -> client region
        input.signingRegion = input.signingRegion || signingRegion || region;
        //signing name resolving order:
        //user supplied signingName -> endpoints.json inferred (credential scope -> model arnNamespace) -> model service id
        input.signingName = input.signingName || signingService || input.serviceId;
        return new SignatureV4({
          credentials: normalizedCreds,
          region: input.signingRegion,
          service: input.signingName,
          sha256: sha256,
          uriEscapePath: signingEscapePath
        });
      });
    };
  }
  return __assign(__assign({}, input), {
    systemClockOffset: systemClockOffset,
    signingEscapePath: signingEscapePath,
    credentials: normalizedCreds,
    signer: signer
  });
}
function normalizeProvider(input) {
  if (typeof input === "object") {
    var promisified_1 = Promise.resolve(input);
    return function () {
      return promisified_1;
    };
  }
  return input;
}
