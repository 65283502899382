import { __awaiter, __generator } from "tslib";
import bowser from "bowser";
/**
 * Default provider to the user agent in browsers. It's a best effort to infer
 * the device information. It uses bowser library to detect the browser and virsion
 */
export var defaultUserAgent = function (_a) {
  var serviceId = _a.serviceId,
    clientVersion = _a.clientVersion;
  return function () {
    return __awaiter(void 0, void 0, void 0, function () {
      var parsedUA, sections;
      var _a, _b, _c, _d, _e, _f, _g;
      return __generator(this, function (_h) {
        parsedUA = ((_a = window === null || window === void 0 ? void 0 : window.navigator) === null || _a === void 0 ? void 0 : _a.userAgent) ? bowser.parse(window.navigator.userAgent) : undefined;
        sections = [
        // sdk-metadata
        ["aws-sdk-js", clientVersion],
        // os-metadata
        ["os/" + (((_b = parsedUA === null || parsedUA === void 0 ? void 0 : parsedUA.os) === null || _b === void 0 ? void 0 : _b.name) || "other"), (_c = parsedUA === null || parsedUA === void 0 ? void 0 : parsedUA.os) === null || _c === void 0 ? void 0 : _c.version],
        // language-metadata
        // ECMAScript edition doesn't matter in JS.
        ["lang/js"],
        // browser vendor and version.
        ["md/browser", ((_e = (_d = parsedUA === null || parsedUA === void 0 ? void 0 : parsedUA.browser) === null || _d === void 0 ? void 0 : _d.name) !== null && _e !== void 0 ? _e : "unknown") + "_" + ((_g = (_f = parsedUA === null || parsedUA === void 0 ? void 0 : parsedUA.browser) === null || _f === void 0 ? void 0 : _f.version) !== null && _g !== void 0 ? _g : "unknown")]];
        if (serviceId) {
          // api-metadata
          // service Id may not appear in non-AWS clients
          sections.push(["api/" + serviceId, clientVersion]);
        }
        return [2 /*return*/, sections];
      });
    });
  };
};
