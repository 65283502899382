import { constructStack } from "@aws-sdk/middleware-stack";
var Client = /** @class */function () {
  function Client(config) {
    this.middlewareStack = constructStack();
    this.config = config;
  }
  Client.prototype.send = function (command, optionsOrCb, cb) {
    var options = typeof optionsOrCb !== "function" ? optionsOrCb : undefined;
    var callback = typeof optionsOrCb === "function" ? optionsOrCb : cb;
    var handler = command.resolveMiddleware(this.middlewareStack, this.config, options);
    if (callback) {
      handler(command).then(function (result) {
        return callback(null, result.output);
      }, function (err) {
        return callback(err);
      }).catch(
      // prevent any errors thrown in the callback from triggering an
      // unhandled promise rejection
      function () {});
    } else {
      return handler(command).then(function (result) {
        return result.output;
      });
    }
  };
  Client.prototype.destroy = function () {
    if (this.config.requestHandler.destroy) this.config.requestHandler.destroy();
  };
  return Client;
}();
export { Client };
