import { NO_RETRY_INCREMENT, RETRY_COST, TIMEOUT_RETRY_COST } from "./constants";
export var getDefaultRetryQuota = function (initialRetryTokens) {
  var MAX_CAPACITY = initialRetryTokens;
  var availableCapacity = initialRetryTokens;
  var getCapacityAmount = function (error) {
    return error.name === "TimeoutError" ? TIMEOUT_RETRY_COST : RETRY_COST;
  };
  var hasRetryTokens = function (error) {
    return getCapacityAmount(error) <= availableCapacity;
  };
  var retrieveRetryTokens = function (error) {
    if (!hasRetryTokens(error)) {
      // retryStrategy should stop retrying, and return last error
      throw new Error("No retry token available");
    }
    var capacityAmount = getCapacityAmount(error);
    availableCapacity -= capacityAmount;
    return capacityAmount;
  };
  var releaseRetryTokens = function (capacityReleaseAmount) {
    availableCapacity += capacityReleaseAmount !== null && capacityReleaseAmount !== void 0 ? capacityReleaseAmount : NO_RETRY_INCREMENT;
    availableCapacity = Math.min(availableCapacity, MAX_CAPACITY);
  };
  return Object.freeze({
    hasRetryTokens: hasRetryTokens,
    retrieveRetryTokens: retrieveRetryTokens,
    releaseRetryTokens: releaseRetryTokens
  });
};
