var HttpResponse = /** @class */function () {
  function HttpResponse(options) {
    this.statusCode = options.statusCode;
    this.headers = options.headers || {};
    this.body = options.body;
  }
  HttpResponse.isInstance = function (response) {
    //determine if response is a valid HttpResponse
    if (!response) return false;
    var resp = response;
    return typeof resp.statusCode === "number" && typeof resp.headers === "object";
  };
  return HttpResponse;
}();
export { HttpResponse };
