import { __assign, __values } from "tslib";
import { cloneRequest } from "./cloneRequest";
/**
 * @internal
 */
export function moveHeadersToQuery(request, options) {
  var e_1, _a;
  var _b;
  if (options === void 0) {
    options = {};
  }
  var _c = typeof request.clone === "function" ? request.clone() : cloneRequest(request),
    headers = _c.headers,
    _d = _c.query,
    query = _d === void 0 ? {} : _d;
  try {
    for (var _e = __values(Object.keys(headers)), _f = _e.next(); !_f.done; _f = _e.next()) {
      var name = _f.value;
      var lname = name.toLowerCase();
      if (lname.substr(0, 6) === "x-amz-" && !((_b = options.unhoistableHeaders) === null || _b === void 0 ? void 0 : _b.has(lname))) {
        query[name] = headers[name];
        delete headers[name];
      }
    }
  } catch (e_1_1) {
    e_1 = {
      error: e_1_1
    };
  } finally {
    try {
      if (_f && !_f.done && (_a = _e.return)) _a.call(_e);
    } finally {
      if (e_1) throw e_1.error;
    }
  }
  return __assign(__assign({}, request), {
    headers: headers,
    query: query
  });
}
