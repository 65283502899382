// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
import { I18n as I18nClass } from './I18n';
import { ConsoleLogger as Logger } from '../Logger';
import { Amplify } from '../Amplify';
var logger = new Logger('I18n');
var _config = null;
var _i18n = null;
/**
 * Export I18n APIs
 */
var I18n = /** @class */function () {
  function I18n() {}
  /**
   * @static
   * @method
   * Configure I18n part
   * @param {Object} config - Configuration of the I18n
   */
  I18n.configure = function (config) {
    logger.debug('configure I18n');
    if (!config) {
      return _config;
    }
    _config = Object.assign({}, _config, config.I18n || config);
    I18n.createInstance();
    return _config;
  };
  I18n.getModuleName = function () {
    return 'I18n';
  };
  /**
   * @static
   * @method
   * Create an instance of I18n for the library
   */
  I18n.createInstance = function () {
    logger.debug('create I18n instance');
    if (_i18n) {
      return;
    }
    _i18n = new I18nClass(_config);
  };
  /**
   * @static @method
   * Explicitly setting language
   * @param {String} lang
   */
  I18n.setLanguage = function (lang) {
    I18n.checkConfig();
    return _i18n.setLanguage(lang);
  };
  /**
   * @static @method
   * Get value
   * @param {String} key
   * @param {String} defVal - Default value
   */
  I18n.get = function (key, defVal) {
    if (!I18n.checkConfig()) {
      return typeof defVal === 'undefined' ? key : defVal;
    }
    return _i18n.get(key, defVal);
  };
  /**
   * @static
   * @method
   * Add vocabularies for one language
   * @param {String} langurage - Language of the dictionary
   * @param {Object} vocabularies - Object that has key-value as dictionary entry
   */
  I18n.putVocabulariesForLanguage = function (language, vocabularies) {
    I18n.checkConfig();
    return _i18n.putVocabulariesForLanguage(language, vocabularies);
  };
  /**
   * @static
   * @method
   * Add vocabularies for one language
   * @param {Object} vocabularies - Object that has language as key,
   *                                vocabularies of each language as value
   */
  I18n.putVocabularies = function (vocabularies) {
    I18n.checkConfig();
    return _i18n.putVocabularies(vocabularies);
  };
  I18n.checkConfig = function () {
    if (!_i18n) {
      _i18n = new I18nClass(_config);
    }
    return true;
  };
  return I18n;
}();
export { I18n };
Amplify.register(I18n);
