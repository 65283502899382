/**
 * Lazy String holder for JSON typed contents.
 */
import { __extends, __read, __spread } from "tslib";
/**
 * Because of https://github.com/microsoft/tslib/issues/95,
 * TS 'extends' shim doesn't support extending native types like String.
 * So here we create StringWrapper that duplicate everything from String
 * class including its prototype chain. So we can extend from here.
 */
// @ts-ignore StringWrapper implementation is not a simple constructor
export var StringWrapper = function () {
  //@ts-ignore 'this' cannot be assigned to any, but Object.getPrototypeOf accepts any
  var Class = Object.getPrototypeOf(this).constructor;
  var Constructor = Function.bind.apply(String, __spread([null], arguments));
  //@ts-ignore Call wrapped String constructor directly, don't bother typing it.
  var instance = new Constructor();
  Object.setPrototypeOf(instance, Class.prototype);
  return instance;
};
StringWrapper.prototype = Object.create(String.prototype, {
  constructor: {
    value: StringWrapper,
    enumerable: false,
    writable: true,
    configurable: true
  }
});
Object.setPrototypeOf(StringWrapper, String);
var LazyJsonString = /** @class */function (_super) {
  __extends(LazyJsonString, _super);
  function LazyJsonString() {
    return _super !== null && _super.apply(this, arguments) || this;
  }
  LazyJsonString.prototype.deserializeJSON = function () {
    return JSON.parse(_super.prototype.toString.call(this));
  };
  LazyJsonString.prototype.toJSON = function () {
    return _super.prototype.toString.call(this);
  };
  LazyJsonString.fromObject = function (object) {
    if (object instanceof LazyJsonString) {
      return object;
    } else if (object instanceof String || typeof object === "string") {
      return new LazyJsonString(object);
    }
    return new LazyJsonString(JSON.stringify(object));
  };
  return LazyJsonString;
}(StringWrapper);
export { LazyJsonString };
