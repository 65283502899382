import { __assign } from "tslib";
export var REGION_ENV_NAME = "AWS_REGION";
export var REGION_INI_NAME = "region";
export var NODE_REGION_CONFIG_OPTIONS = {
  environmentVariableSelector: function (env) {
    return env[REGION_ENV_NAME];
  },
  configFileSelector: function (profile) {
    return profile[REGION_INI_NAME];
  },
  default: function () {
    throw new Error("Region is missing");
  }
};
export var NODE_REGION_CONFIG_FILE_OPTIONS = {
  preferredFile: "credentials"
};
export var resolveRegionConfig = function (input) {
  if (!input.region) {
    throw new Error("Region is missing");
  }
  return __assign(__assign({}, input), {
    region: normalizeRegion(input.region)
  });
};
var normalizeRegion = function (region) {
  if (typeof region === "string") {
    var promisified_1 = Promise.resolve(region);
    return function () {
      return promisified_1;
    };
  }
  return region;
};
