import { __values } from "tslib";
import { cloneRequest } from "./cloneRequest";
import { GENERATED_HEADERS } from "./constants";
/**
 * @internal
 */
export function prepareRequest(request) {
  var e_1, _a;
  // Create a clone of the request object that does not clone the body
  request = typeof request.clone === "function" ? request.clone() : cloneRequest(request);
  try {
    for (var _b = __values(Object.keys(request.headers)), _c = _b.next(); !_c.done; _c = _b.next()) {
      var headerName = _c.value;
      if (GENERATED_HEADERS.indexOf(headerName.toLowerCase()) > -1) {
        delete request.headers[headerName];
      }
    }
  } catch (e_1_1) {
    e_1 = {
      error: e_1_1
    };
  } finally {
    try {
      if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
    } finally {
      if (e_1) throw e_1.error;
    }
  }
  return request;
}
