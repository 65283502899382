import { __awaiter, __generator } from "tslib";
import { HttpRequest } from "@aws-sdk/protocol-http";
export function resolveHostHeaderConfig(input) {
  return input;
}
export var hostHeaderMiddleware = function (options) {
  return function (next) {
    return function (args) {
      return __awaiter(void 0, void 0, void 0, function () {
        var request, _a, handlerProtocol;
        return __generator(this, function (_b) {
          if (!HttpRequest.isInstance(args.request)) return [2 /*return*/, next(args)];
          request = args.request;
          _a = (options.requestHandler.metadata || {}).handlerProtocol, handlerProtocol = _a === void 0 ? "" : _a;
          //For H2 request, remove 'host' header and use ':authority' header instead
          //reference: https://nodejs.org/dist/latest-v13.x/docs/api/errors.html#ERR_HTTP2_INVALID_CONNECTION_HEADERS
          if (handlerProtocol.indexOf("h2") >= 0 && !request.headers[":authority"]) {
            delete request.headers["host"];
            request.headers[":authority"] = "";
            //non-H2 request and 'host' header is not set, set the 'host' header to request's hostname.
          } else if (!request.headers["host"]) {
            request.headers["host"] = request.hostname;
          }
          return [2 /*return*/, next(args)];
        });
      });
    };
  };
};
export var hostHeaderMiddlewareOptions = {
  name: "hostHeaderMiddleware",
  step: "build",
  priority: "low",
  tags: ["HOST"],
  override: true
};
export var getHostHeaderPlugin = function (options) {
  return {
    applyToStack: function (clientStack) {
      clientStack.add(hostHeaderMiddleware(options), hostHeaderMiddlewareOptions);
    }
  };
};
