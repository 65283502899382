import { __assign } from "tslib";
import { DEFAULT_MAX_ATTEMPTS, DEFAULT_RETRY_MODE, StandardRetryStrategy } from "./defaultStrategy";
export var ENV_MAX_ATTEMPTS = "AWS_MAX_ATTEMPTS";
export var CONFIG_MAX_ATTEMPTS = "max_attempts";
export var NODE_MAX_ATTEMPT_CONFIG_OPTIONS = {
  environmentVariableSelector: function (env) {
    var value = env[ENV_MAX_ATTEMPTS];
    if (!value) return undefined;
    var maxAttempt = parseInt(value);
    if (Number.isNaN(maxAttempt)) {
      throw new Error("Environment variable " + ENV_MAX_ATTEMPTS + " mast be a number, got \"" + value + "\"");
    }
    return maxAttempt;
  },
  configFileSelector: function (profile) {
    var value = profile[CONFIG_MAX_ATTEMPTS];
    if (!value) return undefined;
    var maxAttempt = parseInt(value);
    if (Number.isNaN(maxAttempt)) {
      throw new Error("Shared config file entry " + CONFIG_MAX_ATTEMPTS + " mast be a number, got \"" + value + "\"");
    }
    return maxAttempt;
  },
  default: DEFAULT_MAX_ATTEMPTS
};
export var resolveRetryConfig = function (input) {
  var maxAttempts = normalizeMaxAttempts(input.maxAttempts);
  return __assign(__assign({}, input), {
    maxAttempts: maxAttempts,
    retryStrategy: input.retryStrategy || new StandardRetryStrategy(maxAttempts)
  });
};
var normalizeMaxAttempts = function (maxAttempts) {
  if (maxAttempts === void 0) {
    maxAttempts = DEFAULT_MAX_ATTEMPTS;
  }
  if (typeof maxAttempts === "number") {
    var promisified_1 = Promise.resolve(maxAttempts);
    return function () {
      return promisified_1;
    };
  }
  return maxAttempts;
};
export var ENV_RETRY_MODE = "AWS_RETRY_MODE";
export var CONFIG_RETRY_MODE = "retry_mode";
export var NODE_RETRY_MODE_CONFIG_OPTIONS = {
  environmentVariableSelector: function (env) {
    return env[ENV_RETRY_MODE];
  },
  configFileSelector: function (profile) {
    return profile[CONFIG_RETRY_MODE];
  },
  default: DEFAULT_RETRY_MODE
};
