import { __assign, __awaiter, __generator, __read } from "tslib";
import { HttpRequest as __HttpRequest } from "@aws-sdk/protocol-http";
export var serializeAws_json1_1CreateIdentityPoolCommand = function (input, context) {
  return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
      headers = {
        "content-type": "application/x-amz-json-1.1",
        "x-amz-target": "AWSCognitoIdentityService.CreateIdentityPool"
      };
      body = JSON.stringify(serializeAws_json1_1CreateIdentityPoolInput(input, context));
      return [2 /*return*/, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
  });
};
export var serializeAws_json1_1DeleteIdentitiesCommand = function (input, context) {
  return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
      headers = {
        "content-type": "application/x-amz-json-1.1",
        "x-amz-target": "AWSCognitoIdentityService.DeleteIdentities"
      };
      body = JSON.stringify(serializeAws_json1_1DeleteIdentitiesInput(input, context));
      return [2 /*return*/, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
  });
};
export var serializeAws_json1_1DeleteIdentityPoolCommand = function (input, context) {
  return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
      headers = {
        "content-type": "application/x-amz-json-1.1",
        "x-amz-target": "AWSCognitoIdentityService.DeleteIdentityPool"
      };
      body = JSON.stringify(serializeAws_json1_1DeleteIdentityPoolInput(input, context));
      return [2 /*return*/, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
  });
};
export var serializeAws_json1_1DescribeIdentityCommand = function (input, context) {
  return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
      headers = {
        "content-type": "application/x-amz-json-1.1",
        "x-amz-target": "AWSCognitoIdentityService.DescribeIdentity"
      };
      body = JSON.stringify(serializeAws_json1_1DescribeIdentityInput(input, context));
      return [2 /*return*/, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
  });
};
export var serializeAws_json1_1DescribeIdentityPoolCommand = function (input, context) {
  return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
      headers = {
        "content-type": "application/x-amz-json-1.1",
        "x-amz-target": "AWSCognitoIdentityService.DescribeIdentityPool"
      };
      body = JSON.stringify(serializeAws_json1_1DescribeIdentityPoolInput(input, context));
      return [2 /*return*/, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
  });
};
export var serializeAws_json1_1GetCredentialsForIdentityCommand = function (input, context) {
  return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
      headers = {
        "content-type": "application/x-amz-json-1.1",
        "x-amz-target": "AWSCognitoIdentityService.GetCredentialsForIdentity"
      };
      body = JSON.stringify(serializeAws_json1_1GetCredentialsForIdentityInput(input, context));
      return [2 /*return*/, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
  });
};
export var serializeAws_json1_1GetIdCommand = function (input, context) {
  return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
      headers = {
        "content-type": "application/x-amz-json-1.1",
        "x-amz-target": "AWSCognitoIdentityService.GetId"
      };
      body = JSON.stringify(serializeAws_json1_1GetIdInput(input, context));
      return [2 /*return*/, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
  });
};
export var serializeAws_json1_1GetIdentityPoolRolesCommand = function (input, context) {
  return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
      headers = {
        "content-type": "application/x-amz-json-1.1",
        "x-amz-target": "AWSCognitoIdentityService.GetIdentityPoolRoles"
      };
      body = JSON.stringify(serializeAws_json1_1GetIdentityPoolRolesInput(input, context));
      return [2 /*return*/, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
  });
};
export var serializeAws_json1_1GetOpenIdTokenCommand = function (input, context) {
  return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
      headers = {
        "content-type": "application/x-amz-json-1.1",
        "x-amz-target": "AWSCognitoIdentityService.GetOpenIdToken"
      };
      body = JSON.stringify(serializeAws_json1_1GetOpenIdTokenInput(input, context));
      return [2 /*return*/, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
  });
};
export var serializeAws_json1_1GetOpenIdTokenForDeveloperIdentityCommand = function (input, context) {
  return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
      headers = {
        "content-type": "application/x-amz-json-1.1",
        "x-amz-target": "AWSCognitoIdentityService.GetOpenIdTokenForDeveloperIdentity"
      };
      body = JSON.stringify(serializeAws_json1_1GetOpenIdTokenForDeveloperIdentityInput(input, context));
      return [2 /*return*/, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
  });
};
export var serializeAws_json1_1ListIdentitiesCommand = function (input, context) {
  return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
      headers = {
        "content-type": "application/x-amz-json-1.1",
        "x-amz-target": "AWSCognitoIdentityService.ListIdentities"
      };
      body = JSON.stringify(serializeAws_json1_1ListIdentitiesInput(input, context));
      return [2 /*return*/, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
  });
};
export var serializeAws_json1_1ListIdentityPoolsCommand = function (input, context) {
  return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
      headers = {
        "content-type": "application/x-amz-json-1.1",
        "x-amz-target": "AWSCognitoIdentityService.ListIdentityPools"
      };
      body = JSON.stringify(serializeAws_json1_1ListIdentityPoolsInput(input, context));
      return [2 /*return*/, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
  });
};
export var serializeAws_json1_1ListTagsForResourceCommand = function (input, context) {
  return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
      headers = {
        "content-type": "application/x-amz-json-1.1",
        "x-amz-target": "AWSCognitoIdentityService.ListTagsForResource"
      };
      body = JSON.stringify(serializeAws_json1_1ListTagsForResourceInput(input, context));
      return [2 /*return*/, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
  });
};
export var serializeAws_json1_1LookupDeveloperIdentityCommand = function (input, context) {
  return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
      headers = {
        "content-type": "application/x-amz-json-1.1",
        "x-amz-target": "AWSCognitoIdentityService.LookupDeveloperIdentity"
      };
      body = JSON.stringify(serializeAws_json1_1LookupDeveloperIdentityInput(input, context));
      return [2 /*return*/, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
  });
};
export var serializeAws_json1_1MergeDeveloperIdentitiesCommand = function (input, context) {
  return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
      headers = {
        "content-type": "application/x-amz-json-1.1",
        "x-amz-target": "AWSCognitoIdentityService.MergeDeveloperIdentities"
      };
      body = JSON.stringify(serializeAws_json1_1MergeDeveloperIdentitiesInput(input, context));
      return [2 /*return*/, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
  });
};
export var serializeAws_json1_1SetIdentityPoolRolesCommand = function (input, context) {
  return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
      headers = {
        "content-type": "application/x-amz-json-1.1",
        "x-amz-target": "AWSCognitoIdentityService.SetIdentityPoolRoles"
      };
      body = JSON.stringify(serializeAws_json1_1SetIdentityPoolRolesInput(input, context));
      return [2 /*return*/, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
  });
};
export var serializeAws_json1_1TagResourceCommand = function (input, context) {
  return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
      headers = {
        "content-type": "application/x-amz-json-1.1",
        "x-amz-target": "AWSCognitoIdentityService.TagResource"
      };
      body = JSON.stringify(serializeAws_json1_1TagResourceInput(input, context));
      return [2 /*return*/, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
  });
};
export var serializeAws_json1_1UnlinkDeveloperIdentityCommand = function (input, context) {
  return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
      headers = {
        "content-type": "application/x-amz-json-1.1",
        "x-amz-target": "AWSCognitoIdentityService.UnlinkDeveloperIdentity"
      };
      body = JSON.stringify(serializeAws_json1_1UnlinkDeveloperIdentityInput(input, context));
      return [2 /*return*/, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
  });
};
export var serializeAws_json1_1UnlinkIdentityCommand = function (input, context) {
  return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
      headers = {
        "content-type": "application/x-amz-json-1.1",
        "x-amz-target": "AWSCognitoIdentityService.UnlinkIdentity"
      };
      body = JSON.stringify(serializeAws_json1_1UnlinkIdentityInput(input, context));
      return [2 /*return*/, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
  });
};
export var serializeAws_json1_1UntagResourceCommand = function (input, context) {
  return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
      headers = {
        "content-type": "application/x-amz-json-1.1",
        "x-amz-target": "AWSCognitoIdentityService.UntagResource"
      };
      body = JSON.stringify(serializeAws_json1_1UntagResourceInput(input, context));
      return [2 /*return*/, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
  });
};
export var serializeAws_json1_1UpdateIdentityPoolCommand = function (input, context) {
  return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
      headers = {
        "content-type": "application/x-amz-json-1.1",
        "x-amz-target": "AWSCognitoIdentityService.UpdateIdentityPool"
      };
      body = JSON.stringify(serializeAws_json1_1IdentityPool(input, context));
      return [2 /*return*/, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
  });
};
export var deserializeAws_json1_1CreateIdentityPoolCommand = function (output, context) {
  return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          if (output.statusCode >= 300) {
            return [2 /*return*/, deserializeAws_json1_1CreateIdentityPoolCommandError(output, context)];
          }
          return [4 /*yield*/, parseBody(output.body, context)];
        case 1:
          data = _a.sent();
          contents = {};
          contents = deserializeAws_json1_1IdentityPool(data, context);
          response = __assign({
            $metadata: deserializeMetadata(output)
          }, contents);
          return [2 /*return*/, Promise.resolve(response)];
      }
    });
  });
};
var deserializeAws_json1_1CreateIdentityPoolCommandError = function (output, context) {
  return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, _f, _g, _h, parsedBody, message;
    var _j;
    return __generator(this, function (_k) {
      switch (_k.label) {
        case 0:
          _a = [__assign({}, output)];
          _j = {};
          return [4 /*yield*/, parseBody(output.body, context)];
        case 1:
          parsedOutput = __assign.apply(void 0, _a.concat([(_j.body = _k.sent(), _j)]));
          errorCode = "UnknownError";
          errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
          _b = errorCode;
          switch (_b) {
            case "InternalErrorException":
              return [3 /*break*/, 2];
            case "com.amazonaws.cognitoidentity#InternalErrorException":
              return [3 /*break*/, 2];
            case "InvalidParameterException":
              return [3 /*break*/, 4];
            case "com.amazonaws.cognitoidentity#InvalidParameterException":
              return [3 /*break*/, 4];
            case "LimitExceededException":
              return [3 /*break*/, 6];
            case "com.amazonaws.cognitoidentity#LimitExceededException":
              return [3 /*break*/, 6];
            case "NotAuthorizedException":
              return [3 /*break*/, 8];
            case "com.amazonaws.cognitoidentity#NotAuthorizedException":
              return [3 /*break*/, 8];
            case "ResourceConflictException":
              return [3 /*break*/, 10];
            case "com.amazonaws.cognitoidentity#ResourceConflictException":
              return [3 /*break*/, 10];
            case "TooManyRequestsException":
              return [3 /*break*/, 12];
            case "com.amazonaws.cognitoidentity#TooManyRequestsException":
              return [3 /*break*/, 12];
          }
          return [3 /*break*/, 14];
        case 2:
          _c = [{}];
          return [4 /*yield*/, deserializeAws_json1_1InternalErrorExceptionResponse(parsedOutput, context)];
        case 3:
          response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([_k.sent()])), {
            name: errorCode,
            $metadata: deserializeMetadata(output)
          }]);
          return [3 /*break*/, 15];
        case 4:
          _d = [{}];
          return [4 /*yield*/, deserializeAws_json1_1InvalidParameterExceptionResponse(parsedOutput, context)];
        case 5:
          response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([_k.sent()])), {
            name: errorCode,
            $metadata: deserializeMetadata(output)
          }]);
          return [3 /*break*/, 15];
        case 6:
          _e = [{}];
          return [4 /*yield*/, deserializeAws_json1_1LimitExceededExceptionResponse(parsedOutput, context)];
        case 7:
          response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([_k.sent()])), {
            name: errorCode,
            $metadata: deserializeMetadata(output)
          }]);
          return [3 /*break*/, 15];
        case 8:
          _f = [{}];
          return [4 /*yield*/, deserializeAws_json1_1NotAuthorizedExceptionResponse(parsedOutput, context)];
        case 9:
          response = __assign.apply(void 0, [__assign.apply(void 0, _f.concat([_k.sent()])), {
            name: errorCode,
            $metadata: deserializeMetadata(output)
          }]);
          return [3 /*break*/, 15];
        case 10:
          _g = [{}];
          return [4 /*yield*/, deserializeAws_json1_1ResourceConflictExceptionResponse(parsedOutput, context)];
        case 11:
          response = __assign.apply(void 0, [__assign.apply(void 0, _g.concat([_k.sent()])), {
            name: errorCode,
            $metadata: deserializeMetadata(output)
          }]);
          return [3 /*break*/, 15];
        case 12:
          _h = [{}];
          return [4 /*yield*/, deserializeAws_json1_1TooManyRequestsExceptionResponse(parsedOutput, context)];
        case 13:
          response = __assign.apply(void 0, [__assign.apply(void 0, _h.concat([_k.sent()])), {
            name: errorCode,
            $metadata: deserializeMetadata(output)
          }]);
          return [3 /*break*/, 15];
        case 14:
          parsedBody = parsedOutput.body;
          errorCode = parsedBody.code || parsedBody.Code || errorCode;
          response = __assign(__assign({}, parsedBody), {
            name: "" + errorCode,
            message: parsedBody.message || parsedBody.Message || errorCode,
            $fault: "client",
            $metadata: deserializeMetadata(output)
          });
          _k.label = 15;
        case 15:
          message = response.message || response.Message || errorCode;
          response.message = message;
          delete response.Message;
          return [2 /*return*/, Promise.reject(Object.assign(new Error(message), response))];
      }
    });
  });
};
export var deserializeAws_json1_1DeleteIdentitiesCommand = function (output, context) {
  return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          if (output.statusCode >= 300) {
            return [2 /*return*/, deserializeAws_json1_1DeleteIdentitiesCommandError(output, context)];
          }
          return [4 /*yield*/, parseBody(output.body, context)];
        case 1:
          data = _a.sent();
          contents = {};
          contents = deserializeAws_json1_1DeleteIdentitiesResponse(data, context);
          response = __assign({
            $metadata: deserializeMetadata(output)
          }, contents);
          return [2 /*return*/, Promise.resolve(response)];
      }
    });
  });
};
var deserializeAws_json1_1DeleteIdentitiesCommandError = function (output, context) {
  return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, parsedBody, message;
    var _f;
    return __generator(this, function (_g) {
      switch (_g.label) {
        case 0:
          _a = [__assign({}, output)];
          _f = {};
          return [4 /*yield*/, parseBody(output.body, context)];
        case 1:
          parsedOutput = __assign.apply(void 0, _a.concat([(_f.body = _g.sent(), _f)]));
          errorCode = "UnknownError";
          errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
          _b = errorCode;
          switch (_b) {
            case "InternalErrorException":
              return [3 /*break*/, 2];
            case "com.amazonaws.cognitoidentity#InternalErrorException":
              return [3 /*break*/, 2];
            case "InvalidParameterException":
              return [3 /*break*/, 4];
            case "com.amazonaws.cognitoidentity#InvalidParameterException":
              return [3 /*break*/, 4];
            case "TooManyRequestsException":
              return [3 /*break*/, 6];
            case "com.amazonaws.cognitoidentity#TooManyRequestsException":
              return [3 /*break*/, 6];
          }
          return [3 /*break*/, 8];
        case 2:
          _c = [{}];
          return [4 /*yield*/, deserializeAws_json1_1InternalErrorExceptionResponse(parsedOutput, context)];
        case 3:
          response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([_g.sent()])), {
            name: errorCode,
            $metadata: deserializeMetadata(output)
          }]);
          return [3 /*break*/, 9];
        case 4:
          _d = [{}];
          return [4 /*yield*/, deserializeAws_json1_1InvalidParameterExceptionResponse(parsedOutput, context)];
        case 5:
          response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([_g.sent()])), {
            name: errorCode,
            $metadata: deserializeMetadata(output)
          }]);
          return [3 /*break*/, 9];
        case 6:
          _e = [{}];
          return [4 /*yield*/, deserializeAws_json1_1TooManyRequestsExceptionResponse(parsedOutput, context)];
        case 7:
          response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([_g.sent()])), {
            name: errorCode,
            $metadata: deserializeMetadata(output)
          }]);
          return [3 /*break*/, 9];
        case 8:
          parsedBody = parsedOutput.body;
          errorCode = parsedBody.code || parsedBody.Code || errorCode;
          response = __assign(__assign({}, parsedBody), {
            name: "" + errorCode,
            message: parsedBody.message || parsedBody.Message || errorCode,
            $fault: "client",
            $metadata: deserializeMetadata(output)
          });
          _g.label = 9;
        case 9:
          message = response.message || response.Message || errorCode;
          response.message = message;
          delete response.Message;
          return [2 /*return*/, Promise.reject(Object.assign(new Error(message), response))];
      }
    });
  });
};
export var deserializeAws_json1_1DeleteIdentityPoolCommand = function (output, context) {
  return __awaiter(void 0, void 0, void 0, function () {
    var response;
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          if (output.statusCode >= 300) {
            return [2 /*return*/, deserializeAws_json1_1DeleteIdentityPoolCommandError(output, context)];
          }
          return [4 /*yield*/, collectBody(output.body, context)];
        case 1:
          _a.sent();
          response = {
            $metadata: deserializeMetadata(output)
          };
          return [2 /*return*/, Promise.resolve(response)];
      }
    });
  });
};
var deserializeAws_json1_1DeleteIdentityPoolCommandError = function (output, context) {
  return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, _f, _g, parsedBody, message;
    var _h;
    return __generator(this, function (_j) {
      switch (_j.label) {
        case 0:
          _a = [__assign({}, output)];
          _h = {};
          return [4 /*yield*/, parseBody(output.body, context)];
        case 1:
          parsedOutput = __assign.apply(void 0, _a.concat([(_h.body = _j.sent(), _h)]));
          errorCode = "UnknownError";
          errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
          _b = errorCode;
          switch (_b) {
            case "InternalErrorException":
              return [3 /*break*/, 2];
            case "com.amazonaws.cognitoidentity#InternalErrorException":
              return [3 /*break*/, 2];
            case "InvalidParameterException":
              return [3 /*break*/, 4];
            case "com.amazonaws.cognitoidentity#InvalidParameterException":
              return [3 /*break*/, 4];
            case "NotAuthorizedException":
              return [3 /*break*/, 6];
            case "com.amazonaws.cognitoidentity#NotAuthorizedException":
              return [3 /*break*/, 6];
            case "ResourceNotFoundException":
              return [3 /*break*/, 8];
            case "com.amazonaws.cognitoidentity#ResourceNotFoundException":
              return [3 /*break*/, 8];
            case "TooManyRequestsException":
              return [3 /*break*/, 10];
            case "com.amazonaws.cognitoidentity#TooManyRequestsException":
              return [3 /*break*/, 10];
          }
          return [3 /*break*/, 12];
        case 2:
          _c = [{}];
          return [4 /*yield*/, deserializeAws_json1_1InternalErrorExceptionResponse(parsedOutput, context)];
        case 3:
          response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([_j.sent()])), {
            name: errorCode,
            $metadata: deserializeMetadata(output)
          }]);
          return [3 /*break*/, 13];
        case 4:
          _d = [{}];
          return [4 /*yield*/, deserializeAws_json1_1InvalidParameterExceptionResponse(parsedOutput, context)];
        case 5:
          response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([_j.sent()])), {
            name: errorCode,
            $metadata: deserializeMetadata(output)
          }]);
          return [3 /*break*/, 13];
        case 6:
          _e = [{}];
          return [4 /*yield*/, deserializeAws_json1_1NotAuthorizedExceptionResponse(parsedOutput, context)];
        case 7:
          response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([_j.sent()])), {
            name: errorCode,
            $metadata: deserializeMetadata(output)
          }]);
          return [3 /*break*/, 13];
        case 8:
          _f = [{}];
          return [4 /*yield*/, deserializeAws_json1_1ResourceNotFoundExceptionResponse(parsedOutput, context)];
        case 9:
          response = __assign.apply(void 0, [__assign.apply(void 0, _f.concat([_j.sent()])), {
            name: errorCode,
            $metadata: deserializeMetadata(output)
          }]);
          return [3 /*break*/, 13];
        case 10:
          _g = [{}];
          return [4 /*yield*/, deserializeAws_json1_1TooManyRequestsExceptionResponse(parsedOutput, context)];
        case 11:
          response = __assign.apply(void 0, [__assign.apply(void 0, _g.concat([_j.sent()])), {
            name: errorCode,
            $metadata: deserializeMetadata(output)
          }]);
          return [3 /*break*/, 13];
        case 12:
          parsedBody = parsedOutput.body;
          errorCode = parsedBody.code || parsedBody.Code || errorCode;
          response = __assign(__assign({}, parsedBody), {
            name: "" + errorCode,
            message: parsedBody.message || parsedBody.Message || errorCode,
            $fault: "client",
            $metadata: deserializeMetadata(output)
          });
          _j.label = 13;
        case 13:
          message = response.message || response.Message || errorCode;
          response.message = message;
          delete response.Message;
          return [2 /*return*/, Promise.reject(Object.assign(new Error(message), response))];
      }
    });
  });
};
export var deserializeAws_json1_1DescribeIdentityCommand = function (output, context) {
  return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          if (output.statusCode >= 300) {
            return [2 /*return*/, deserializeAws_json1_1DescribeIdentityCommandError(output, context)];
          }
          return [4 /*yield*/, parseBody(output.body, context)];
        case 1:
          data = _a.sent();
          contents = {};
          contents = deserializeAws_json1_1IdentityDescription(data, context);
          response = __assign({
            $metadata: deserializeMetadata(output)
          }, contents);
          return [2 /*return*/, Promise.resolve(response)];
      }
    });
  });
};
var deserializeAws_json1_1DescribeIdentityCommandError = function (output, context) {
  return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, _f, _g, parsedBody, message;
    var _h;
    return __generator(this, function (_j) {
      switch (_j.label) {
        case 0:
          _a = [__assign({}, output)];
          _h = {};
          return [4 /*yield*/, parseBody(output.body, context)];
        case 1:
          parsedOutput = __assign.apply(void 0, _a.concat([(_h.body = _j.sent(), _h)]));
          errorCode = "UnknownError";
          errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
          _b = errorCode;
          switch (_b) {
            case "InternalErrorException":
              return [3 /*break*/, 2];
            case "com.amazonaws.cognitoidentity#InternalErrorException":
              return [3 /*break*/, 2];
            case "InvalidParameterException":
              return [3 /*break*/, 4];
            case "com.amazonaws.cognitoidentity#InvalidParameterException":
              return [3 /*break*/, 4];
            case "NotAuthorizedException":
              return [3 /*break*/, 6];
            case "com.amazonaws.cognitoidentity#NotAuthorizedException":
              return [3 /*break*/, 6];
            case "ResourceNotFoundException":
              return [3 /*break*/, 8];
            case "com.amazonaws.cognitoidentity#ResourceNotFoundException":
              return [3 /*break*/, 8];
            case "TooManyRequestsException":
              return [3 /*break*/, 10];
            case "com.amazonaws.cognitoidentity#TooManyRequestsException":
              return [3 /*break*/, 10];
          }
          return [3 /*break*/, 12];
        case 2:
          _c = [{}];
          return [4 /*yield*/, deserializeAws_json1_1InternalErrorExceptionResponse(parsedOutput, context)];
        case 3:
          response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([_j.sent()])), {
            name: errorCode,
            $metadata: deserializeMetadata(output)
          }]);
          return [3 /*break*/, 13];
        case 4:
          _d = [{}];
          return [4 /*yield*/, deserializeAws_json1_1InvalidParameterExceptionResponse(parsedOutput, context)];
        case 5:
          response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([_j.sent()])), {
            name: errorCode,
            $metadata: deserializeMetadata(output)
          }]);
          return [3 /*break*/, 13];
        case 6:
          _e = [{}];
          return [4 /*yield*/, deserializeAws_json1_1NotAuthorizedExceptionResponse(parsedOutput, context)];
        case 7:
          response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([_j.sent()])), {
            name: errorCode,
            $metadata: deserializeMetadata(output)
          }]);
          return [3 /*break*/, 13];
        case 8:
          _f = [{}];
          return [4 /*yield*/, deserializeAws_json1_1ResourceNotFoundExceptionResponse(parsedOutput, context)];
        case 9:
          response = __assign.apply(void 0, [__assign.apply(void 0, _f.concat([_j.sent()])), {
            name: errorCode,
            $metadata: deserializeMetadata(output)
          }]);
          return [3 /*break*/, 13];
        case 10:
          _g = [{}];
          return [4 /*yield*/, deserializeAws_json1_1TooManyRequestsExceptionResponse(parsedOutput, context)];
        case 11:
          response = __assign.apply(void 0, [__assign.apply(void 0, _g.concat([_j.sent()])), {
            name: errorCode,
            $metadata: deserializeMetadata(output)
          }]);
          return [3 /*break*/, 13];
        case 12:
          parsedBody = parsedOutput.body;
          errorCode = parsedBody.code || parsedBody.Code || errorCode;
          response = __assign(__assign({}, parsedBody), {
            name: "" + errorCode,
            message: parsedBody.message || parsedBody.Message || errorCode,
            $fault: "client",
            $metadata: deserializeMetadata(output)
          });
          _j.label = 13;
        case 13:
          message = response.message || response.Message || errorCode;
          response.message = message;
          delete response.Message;
          return [2 /*return*/, Promise.reject(Object.assign(new Error(message), response))];
      }
    });
  });
};
export var deserializeAws_json1_1DescribeIdentityPoolCommand = function (output, context) {
  return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          if (output.statusCode >= 300) {
            return [2 /*return*/, deserializeAws_json1_1DescribeIdentityPoolCommandError(output, context)];
          }
          return [4 /*yield*/, parseBody(output.body, context)];
        case 1:
          data = _a.sent();
          contents = {};
          contents = deserializeAws_json1_1IdentityPool(data, context);
          response = __assign({
            $metadata: deserializeMetadata(output)
          }, contents);
          return [2 /*return*/, Promise.resolve(response)];
      }
    });
  });
};
var deserializeAws_json1_1DescribeIdentityPoolCommandError = function (output, context) {
  return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, _f, _g, parsedBody, message;
    var _h;
    return __generator(this, function (_j) {
      switch (_j.label) {
        case 0:
          _a = [__assign({}, output)];
          _h = {};
          return [4 /*yield*/, parseBody(output.body, context)];
        case 1:
          parsedOutput = __assign.apply(void 0, _a.concat([(_h.body = _j.sent(), _h)]));
          errorCode = "UnknownError";
          errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
          _b = errorCode;
          switch (_b) {
            case "InternalErrorException":
              return [3 /*break*/, 2];
            case "com.amazonaws.cognitoidentity#InternalErrorException":
              return [3 /*break*/, 2];
            case "InvalidParameterException":
              return [3 /*break*/, 4];
            case "com.amazonaws.cognitoidentity#InvalidParameterException":
              return [3 /*break*/, 4];
            case "NotAuthorizedException":
              return [3 /*break*/, 6];
            case "com.amazonaws.cognitoidentity#NotAuthorizedException":
              return [3 /*break*/, 6];
            case "ResourceNotFoundException":
              return [3 /*break*/, 8];
            case "com.amazonaws.cognitoidentity#ResourceNotFoundException":
              return [3 /*break*/, 8];
            case "TooManyRequestsException":
              return [3 /*break*/, 10];
            case "com.amazonaws.cognitoidentity#TooManyRequestsException":
              return [3 /*break*/, 10];
          }
          return [3 /*break*/, 12];
        case 2:
          _c = [{}];
          return [4 /*yield*/, deserializeAws_json1_1InternalErrorExceptionResponse(parsedOutput, context)];
        case 3:
          response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([_j.sent()])), {
            name: errorCode,
            $metadata: deserializeMetadata(output)
          }]);
          return [3 /*break*/, 13];
        case 4:
          _d = [{}];
          return [4 /*yield*/, deserializeAws_json1_1InvalidParameterExceptionResponse(parsedOutput, context)];
        case 5:
          response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([_j.sent()])), {
            name: errorCode,
            $metadata: deserializeMetadata(output)
          }]);
          return [3 /*break*/, 13];
        case 6:
          _e = [{}];
          return [4 /*yield*/, deserializeAws_json1_1NotAuthorizedExceptionResponse(parsedOutput, context)];
        case 7:
          response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([_j.sent()])), {
            name: errorCode,
            $metadata: deserializeMetadata(output)
          }]);
          return [3 /*break*/, 13];
        case 8:
          _f = [{}];
          return [4 /*yield*/, deserializeAws_json1_1ResourceNotFoundExceptionResponse(parsedOutput, context)];
        case 9:
          response = __assign.apply(void 0, [__assign.apply(void 0, _f.concat([_j.sent()])), {
            name: errorCode,
            $metadata: deserializeMetadata(output)
          }]);
          return [3 /*break*/, 13];
        case 10:
          _g = [{}];
          return [4 /*yield*/, deserializeAws_json1_1TooManyRequestsExceptionResponse(parsedOutput, context)];
        case 11:
          response = __assign.apply(void 0, [__assign.apply(void 0, _g.concat([_j.sent()])), {
            name: errorCode,
            $metadata: deserializeMetadata(output)
          }]);
          return [3 /*break*/, 13];
        case 12:
          parsedBody = parsedOutput.body;
          errorCode = parsedBody.code || parsedBody.Code || errorCode;
          response = __assign(__assign({}, parsedBody), {
            name: "" + errorCode,
            message: parsedBody.message || parsedBody.Message || errorCode,
            $fault: "client",
            $metadata: deserializeMetadata(output)
          });
          _j.label = 13;
        case 13:
          message = response.message || response.Message || errorCode;
          response.message = message;
          delete response.Message;
          return [2 /*return*/, Promise.reject(Object.assign(new Error(message), response))];
      }
    });
  });
};
export var deserializeAws_json1_1GetCredentialsForIdentityCommand = function (output, context) {
  return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          if (output.statusCode >= 300) {
            return [2 /*return*/, deserializeAws_json1_1GetCredentialsForIdentityCommandError(output, context)];
          }
          return [4 /*yield*/, parseBody(output.body, context)];
        case 1:
          data = _a.sent();
          contents = {};
          contents = deserializeAws_json1_1GetCredentialsForIdentityResponse(data, context);
          response = __assign({
            $metadata: deserializeMetadata(output)
          }, contents);
          return [2 /*return*/, Promise.resolve(response)];
      }
    });
  });
};
var deserializeAws_json1_1GetCredentialsForIdentityCommandError = function (output, context) {
  return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, _f, _g, _h, _j, _k, parsedBody, message;
    var _l;
    return __generator(this, function (_m) {
      switch (_m.label) {
        case 0:
          _a = [__assign({}, output)];
          _l = {};
          return [4 /*yield*/, parseBody(output.body, context)];
        case 1:
          parsedOutput = __assign.apply(void 0, _a.concat([(_l.body = _m.sent(), _l)]));
          errorCode = "UnknownError";
          errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
          _b = errorCode;
          switch (_b) {
            case "ExternalServiceException":
              return [3 /*break*/, 2];
            case "com.amazonaws.cognitoidentity#ExternalServiceException":
              return [3 /*break*/, 2];
            case "InternalErrorException":
              return [3 /*break*/, 4];
            case "com.amazonaws.cognitoidentity#InternalErrorException":
              return [3 /*break*/, 4];
            case "InvalidIdentityPoolConfigurationException":
              return [3 /*break*/, 6];
            case "com.amazonaws.cognitoidentity#InvalidIdentityPoolConfigurationException":
              return [3 /*break*/, 6];
            case "InvalidParameterException":
              return [3 /*break*/, 8];
            case "com.amazonaws.cognitoidentity#InvalidParameterException":
              return [3 /*break*/, 8];
            case "NotAuthorizedException":
              return [3 /*break*/, 10];
            case "com.amazonaws.cognitoidentity#NotAuthorizedException":
              return [3 /*break*/, 10];
            case "ResourceConflictException":
              return [3 /*break*/, 12];
            case "com.amazonaws.cognitoidentity#ResourceConflictException":
              return [3 /*break*/, 12];
            case "ResourceNotFoundException":
              return [3 /*break*/, 14];
            case "com.amazonaws.cognitoidentity#ResourceNotFoundException":
              return [3 /*break*/, 14];
            case "TooManyRequestsException":
              return [3 /*break*/, 16];
            case "com.amazonaws.cognitoidentity#TooManyRequestsException":
              return [3 /*break*/, 16];
          }
          return [3 /*break*/, 18];
        case 2:
          _c = [{}];
          return [4 /*yield*/, deserializeAws_json1_1ExternalServiceExceptionResponse(parsedOutput, context)];
        case 3:
          response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([_m.sent()])), {
            name: errorCode,
            $metadata: deserializeMetadata(output)
          }]);
          return [3 /*break*/, 19];
        case 4:
          _d = [{}];
          return [4 /*yield*/, deserializeAws_json1_1InternalErrorExceptionResponse(parsedOutput, context)];
        case 5:
          response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([_m.sent()])), {
            name: errorCode,
            $metadata: deserializeMetadata(output)
          }]);
          return [3 /*break*/, 19];
        case 6:
          _e = [{}];
          return [4 /*yield*/, deserializeAws_json1_1InvalidIdentityPoolConfigurationExceptionResponse(parsedOutput, context)];
        case 7:
          response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([_m.sent()])), {
            name: errorCode,
            $metadata: deserializeMetadata(output)
          }]);
          return [3 /*break*/, 19];
        case 8:
          _f = [{}];
          return [4 /*yield*/, deserializeAws_json1_1InvalidParameterExceptionResponse(parsedOutput, context)];
        case 9:
          response = __assign.apply(void 0, [__assign.apply(void 0, _f.concat([_m.sent()])), {
            name: errorCode,
            $metadata: deserializeMetadata(output)
          }]);
          return [3 /*break*/, 19];
        case 10:
          _g = [{}];
          return [4 /*yield*/, deserializeAws_json1_1NotAuthorizedExceptionResponse(parsedOutput, context)];
        case 11:
          response = __assign.apply(void 0, [__assign.apply(void 0, _g.concat([_m.sent()])), {
            name: errorCode,
            $metadata: deserializeMetadata(output)
          }]);
          return [3 /*break*/, 19];
        case 12:
          _h = [{}];
          return [4 /*yield*/, deserializeAws_json1_1ResourceConflictExceptionResponse(parsedOutput, context)];
        case 13:
          response = __assign.apply(void 0, [__assign.apply(void 0, _h.concat([_m.sent()])), {
            name: errorCode,
            $metadata: deserializeMetadata(output)
          }]);
          return [3 /*break*/, 19];
        case 14:
          _j = [{}];
          return [4 /*yield*/, deserializeAws_json1_1ResourceNotFoundExceptionResponse(parsedOutput, context)];
        case 15:
          response = __assign.apply(void 0, [__assign.apply(void 0, _j.concat([_m.sent()])), {
            name: errorCode,
            $metadata: deserializeMetadata(output)
          }]);
          return [3 /*break*/, 19];
        case 16:
          _k = [{}];
          return [4 /*yield*/, deserializeAws_json1_1TooManyRequestsExceptionResponse(parsedOutput, context)];
        case 17:
          response = __assign.apply(void 0, [__assign.apply(void 0, _k.concat([_m.sent()])), {
            name: errorCode,
            $metadata: deserializeMetadata(output)
          }]);
          return [3 /*break*/, 19];
        case 18:
          parsedBody = parsedOutput.body;
          errorCode = parsedBody.code || parsedBody.Code || errorCode;
          response = __assign(__assign({}, parsedBody), {
            name: "" + errorCode,
            message: parsedBody.message || parsedBody.Message || errorCode,
            $fault: "client",
            $metadata: deserializeMetadata(output)
          });
          _m.label = 19;
        case 19:
          message = response.message || response.Message || errorCode;
          response.message = message;
          delete response.Message;
          return [2 /*return*/, Promise.reject(Object.assign(new Error(message), response))];
      }
    });
  });
};
export var deserializeAws_json1_1GetIdCommand = function (output, context) {
  return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          if (output.statusCode >= 300) {
            return [2 /*return*/, deserializeAws_json1_1GetIdCommandError(output, context)];
          }
          return [4 /*yield*/, parseBody(output.body, context)];
        case 1:
          data = _a.sent();
          contents = {};
          contents = deserializeAws_json1_1GetIdResponse(data, context);
          response = __assign({
            $metadata: deserializeMetadata(output)
          }, contents);
          return [2 /*return*/, Promise.resolve(response)];
      }
    });
  });
};
var deserializeAws_json1_1GetIdCommandError = function (output, context) {
  return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, _f, _g, _h, _j, _k, parsedBody, message;
    var _l;
    return __generator(this, function (_m) {
      switch (_m.label) {
        case 0:
          _a = [__assign({}, output)];
          _l = {};
          return [4 /*yield*/, parseBody(output.body, context)];
        case 1:
          parsedOutput = __assign.apply(void 0, _a.concat([(_l.body = _m.sent(), _l)]));
          errorCode = "UnknownError";
          errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
          _b = errorCode;
          switch (_b) {
            case "ExternalServiceException":
              return [3 /*break*/, 2];
            case "com.amazonaws.cognitoidentity#ExternalServiceException":
              return [3 /*break*/, 2];
            case "InternalErrorException":
              return [3 /*break*/, 4];
            case "com.amazonaws.cognitoidentity#InternalErrorException":
              return [3 /*break*/, 4];
            case "InvalidParameterException":
              return [3 /*break*/, 6];
            case "com.amazonaws.cognitoidentity#InvalidParameterException":
              return [3 /*break*/, 6];
            case "LimitExceededException":
              return [3 /*break*/, 8];
            case "com.amazonaws.cognitoidentity#LimitExceededException":
              return [3 /*break*/, 8];
            case "NotAuthorizedException":
              return [3 /*break*/, 10];
            case "com.amazonaws.cognitoidentity#NotAuthorizedException":
              return [3 /*break*/, 10];
            case "ResourceConflictException":
              return [3 /*break*/, 12];
            case "com.amazonaws.cognitoidentity#ResourceConflictException":
              return [3 /*break*/, 12];
            case "ResourceNotFoundException":
              return [3 /*break*/, 14];
            case "com.amazonaws.cognitoidentity#ResourceNotFoundException":
              return [3 /*break*/, 14];
            case "TooManyRequestsException":
              return [3 /*break*/, 16];
            case "com.amazonaws.cognitoidentity#TooManyRequestsException":
              return [3 /*break*/, 16];
          }
          return [3 /*break*/, 18];
        case 2:
          _c = [{}];
          return [4 /*yield*/, deserializeAws_json1_1ExternalServiceExceptionResponse(parsedOutput, context)];
        case 3:
          response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([_m.sent()])), {
            name: errorCode,
            $metadata: deserializeMetadata(output)
          }]);
          return [3 /*break*/, 19];
        case 4:
          _d = [{}];
          return [4 /*yield*/, deserializeAws_json1_1InternalErrorExceptionResponse(parsedOutput, context)];
        case 5:
          response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([_m.sent()])), {
            name: errorCode,
            $metadata: deserializeMetadata(output)
          }]);
          return [3 /*break*/, 19];
        case 6:
          _e = [{}];
          return [4 /*yield*/, deserializeAws_json1_1InvalidParameterExceptionResponse(parsedOutput, context)];
        case 7:
          response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([_m.sent()])), {
            name: errorCode,
            $metadata: deserializeMetadata(output)
          }]);
          return [3 /*break*/, 19];
        case 8:
          _f = [{}];
          return [4 /*yield*/, deserializeAws_json1_1LimitExceededExceptionResponse(parsedOutput, context)];
        case 9:
          response = __assign.apply(void 0, [__assign.apply(void 0, _f.concat([_m.sent()])), {
            name: errorCode,
            $metadata: deserializeMetadata(output)
          }]);
          return [3 /*break*/, 19];
        case 10:
          _g = [{}];
          return [4 /*yield*/, deserializeAws_json1_1NotAuthorizedExceptionResponse(parsedOutput, context)];
        case 11:
          response = __assign.apply(void 0, [__assign.apply(void 0, _g.concat([_m.sent()])), {
            name: errorCode,
            $metadata: deserializeMetadata(output)
          }]);
          return [3 /*break*/, 19];
        case 12:
          _h = [{}];
          return [4 /*yield*/, deserializeAws_json1_1ResourceConflictExceptionResponse(parsedOutput, context)];
        case 13:
          response = __assign.apply(void 0, [__assign.apply(void 0, _h.concat([_m.sent()])), {
            name: errorCode,
            $metadata: deserializeMetadata(output)
          }]);
          return [3 /*break*/, 19];
        case 14:
          _j = [{}];
          return [4 /*yield*/, deserializeAws_json1_1ResourceNotFoundExceptionResponse(parsedOutput, context)];
        case 15:
          response = __assign.apply(void 0, [__assign.apply(void 0, _j.concat([_m.sent()])), {
            name: errorCode,
            $metadata: deserializeMetadata(output)
          }]);
          return [3 /*break*/, 19];
        case 16:
          _k = [{}];
          return [4 /*yield*/, deserializeAws_json1_1TooManyRequestsExceptionResponse(parsedOutput, context)];
        case 17:
          response = __assign.apply(void 0, [__assign.apply(void 0, _k.concat([_m.sent()])), {
            name: errorCode,
            $metadata: deserializeMetadata(output)
          }]);
          return [3 /*break*/, 19];
        case 18:
          parsedBody = parsedOutput.body;
          errorCode = parsedBody.code || parsedBody.Code || errorCode;
          response = __assign(__assign({}, parsedBody), {
            name: "" + errorCode,
            message: parsedBody.message || parsedBody.Message || errorCode,
            $fault: "client",
            $metadata: deserializeMetadata(output)
          });
          _m.label = 19;
        case 19:
          message = response.message || response.Message || errorCode;
          response.message = message;
          delete response.Message;
          return [2 /*return*/, Promise.reject(Object.assign(new Error(message), response))];
      }
    });
  });
};
export var deserializeAws_json1_1GetIdentityPoolRolesCommand = function (output, context) {
  return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          if (output.statusCode >= 300) {
            return [2 /*return*/, deserializeAws_json1_1GetIdentityPoolRolesCommandError(output, context)];
          }
          return [4 /*yield*/, parseBody(output.body, context)];
        case 1:
          data = _a.sent();
          contents = {};
          contents = deserializeAws_json1_1GetIdentityPoolRolesResponse(data, context);
          response = __assign({
            $metadata: deserializeMetadata(output)
          }, contents);
          return [2 /*return*/, Promise.resolve(response)];
      }
    });
  });
};
var deserializeAws_json1_1GetIdentityPoolRolesCommandError = function (output, context) {
  return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, _f, _g, _h, parsedBody, message;
    var _j;
    return __generator(this, function (_k) {
      switch (_k.label) {
        case 0:
          _a = [__assign({}, output)];
          _j = {};
          return [4 /*yield*/, parseBody(output.body, context)];
        case 1:
          parsedOutput = __assign.apply(void 0, _a.concat([(_j.body = _k.sent(), _j)]));
          errorCode = "UnknownError";
          errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
          _b = errorCode;
          switch (_b) {
            case "InternalErrorException":
              return [3 /*break*/, 2];
            case "com.amazonaws.cognitoidentity#InternalErrorException":
              return [3 /*break*/, 2];
            case "InvalidParameterException":
              return [3 /*break*/, 4];
            case "com.amazonaws.cognitoidentity#InvalidParameterException":
              return [3 /*break*/, 4];
            case "NotAuthorizedException":
              return [3 /*break*/, 6];
            case "com.amazonaws.cognitoidentity#NotAuthorizedException":
              return [3 /*break*/, 6];
            case "ResourceConflictException":
              return [3 /*break*/, 8];
            case "com.amazonaws.cognitoidentity#ResourceConflictException":
              return [3 /*break*/, 8];
            case "ResourceNotFoundException":
              return [3 /*break*/, 10];
            case "com.amazonaws.cognitoidentity#ResourceNotFoundException":
              return [3 /*break*/, 10];
            case "TooManyRequestsException":
              return [3 /*break*/, 12];
            case "com.amazonaws.cognitoidentity#TooManyRequestsException":
              return [3 /*break*/, 12];
          }
          return [3 /*break*/, 14];
        case 2:
          _c = [{}];
          return [4 /*yield*/, deserializeAws_json1_1InternalErrorExceptionResponse(parsedOutput, context)];
        case 3:
          response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([_k.sent()])), {
            name: errorCode,
            $metadata: deserializeMetadata(output)
          }]);
          return [3 /*break*/, 15];
        case 4:
          _d = [{}];
          return [4 /*yield*/, deserializeAws_json1_1InvalidParameterExceptionResponse(parsedOutput, context)];
        case 5:
          response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([_k.sent()])), {
            name: errorCode,
            $metadata: deserializeMetadata(output)
          }]);
          return [3 /*break*/, 15];
        case 6:
          _e = [{}];
          return [4 /*yield*/, deserializeAws_json1_1NotAuthorizedExceptionResponse(parsedOutput, context)];
        case 7:
          response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([_k.sent()])), {
            name: errorCode,
            $metadata: deserializeMetadata(output)
          }]);
          return [3 /*break*/, 15];
        case 8:
          _f = [{}];
          return [4 /*yield*/, deserializeAws_json1_1ResourceConflictExceptionResponse(parsedOutput, context)];
        case 9:
          response = __assign.apply(void 0, [__assign.apply(void 0, _f.concat([_k.sent()])), {
            name: errorCode,
            $metadata: deserializeMetadata(output)
          }]);
          return [3 /*break*/, 15];
        case 10:
          _g = [{}];
          return [4 /*yield*/, deserializeAws_json1_1ResourceNotFoundExceptionResponse(parsedOutput, context)];
        case 11:
          response = __assign.apply(void 0, [__assign.apply(void 0, _g.concat([_k.sent()])), {
            name: errorCode,
            $metadata: deserializeMetadata(output)
          }]);
          return [3 /*break*/, 15];
        case 12:
          _h = [{}];
          return [4 /*yield*/, deserializeAws_json1_1TooManyRequestsExceptionResponse(parsedOutput, context)];
        case 13:
          response = __assign.apply(void 0, [__assign.apply(void 0, _h.concat([_k.sent()])), {
            name: errorCode,
            $metadata: deserializeMetadata(output)
          }]);
          return [3 /*break*/, 15];
        case 14:
          parsedBody = parsedOutput.body;
          errorCode = parsedBody.code || parsedBody.Code || errorCode;
          response = __assign(__assign({}, parsedBody), {
            name: "" + errorCode,
            message: parsedBody.message || parsedBody.Message || errorCode,
            $fault: "client",
            $metadata: deserializeMetadata(output)
          });
          _k.label = 15;
        case 15:
          message = response.message || response.Message || errorCode;
          response.message = message;
          delete response.Message;
          return [2 /*return*/, Promise.reject(Object.assign(new Error(message), response))];
      }
    });
  });
};
export var deserializeAws_json1_1GetOpenIdTokenCommand = function (output, context) {
  return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          if (output.statusCode >= 300) {
            return [2 /*return*/, deserializeAws_json1_1GetOpenIdTokenCommandError(output, context)];
          }
          return [4 /*yield*/, parseBody(output.body, context)];
        case 1:
          data = _a.sent();
          contents = {};
          contents = deserializeAws_json1_1GetOpenIdTokenResponse(data, context);
          response = __assign({
            $metadata: deserializeMetadata(output)
          }, contents);
          return [2 /*return*/, Promise.resolve(response)];
      }
    });
  });
};
var deserializeAws_json1_1GetOpenIdTokenCommandError = function (output, context) {
  return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, _f, _g, _h, _j, parsedBody, message;
    var _k;
    return __generator(this, function (_l) {
      switch (_l.label) {
        case 0:
          _a = [__assign({}, output)];
          _k = {};
          return [4 /*yield*/, parseBody(output.body, context)];
        case 1:
          parsedOutput = __assign.apply(void 0, _a.concat([(_k.body = _l.sent(), _k)]));
          errorCode = "UnknownError";
          errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
          _b = errorCode;
          switch (_b) {
            case "ExternalServiceException":
              return [3 /*break*/, 2];
            case "com.amazonaws.cognitoidentity#ExternalServiceException":
              return [3 /*break*/, 2];
            case "InternalErrorException":
              return [3 /*break*/, 4];
            case "com.amazonaws.cognitoidentity#InternalErrorException":
              return [3 /*break*/, 4];
            case "InvalidParameterException":
              return [3 /*break*/, 6];
            case "com.amazonaws.cognitoidentity#InvalidParameterException":
              return [3 /*break*/, 6];
            case "NotAuthorizedException":
              return [3 /*break*/, 8];
            case "com.amazonaws.cognitoidentity#NotAuthorizedException":
              return [3 /*break*/, 8];
            case "ResourceConflictException":
              return [3 /*break*/, 10];
            case "com.amazonaws.cognitoidentity#ResourceConflictException":
              return [3 /*break*/, 10];
            case "ResourceNotFoundException":
              return [3 /*break*/, 12];
            case "com.amazonaws.cognitoidentity#ResourceNotFoundException":
              return [3 /*break*/, 12];
            case "TooManyRequestsException":
              return [3 /*break*/, 14];
            case "com.amazonaws.cognitoidentity#TooManyRequestsException":
              return [3 /*break*/, 14];
          }
          return [3 /*break*/, 16];
        case 2:
          _c = [{}];
          return [4 /*yield*/, deserializeAws_json1_1ExternalServiceExceptionResponse(parsedOutput, context)];
        case 3:
          response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([_l.sent()])), {
            name: errorCode,
            $metadata: deserializeMetadata(output)
          }]);
          return [3 /*break*/, 17];
        case 4:
          _d = [{}];
          return [4 /*yield*/, deserializeAws_json1_1InternalErrorExceptionResponse(parsedOutput, context)];
        case 5:
          response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([_l.sent()])), {
            name: errorCode,
            $metadata: deserializeMetadata(output)
          }]);
          return [3 /*break*/, 17];
        case 6:
          _e = [{}];
          return [4 /*yield*/, deserializeAws_json1_1InvalidParameterExceptionResponse(parsedOutput, context)];
        case 7:
          response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([_l.sent()])), {
            name: errorCode,
            $metadata: deserializeMetadata(output)
          }]);
          return [3 /*break*/, 17];
        case 8:
          _f = [{}];
          return [4 /*yield*/, deserializeAws_json1_1NotAuthorizedExceptionResponse(parsedOutput, context)];
        case 9:
          response = __assign.apply(void 0, [__assign.apply(void 0, _f.concat([_l.sent()])), {
            name: errorCode,
            $metadata: deserializeMetadata(output)
          }]);
          return [3 /*break*/, 17];
        case 10:
          _g = [{}];
          return [4 /*yield*/, deserializeAws_json1_1ResourceConflictExceptionResponse(parsedOutput, context)];
        case 11:
          response = __assign.apply(void 0, [__assign.apply(void 0, _g.concat([_l.sent()])), {
            name: errorCode,
            $metadata: deserializeMetadata(output)
          }]);
          return [3 /*break*/, 17];
        case 12:
          _h = [{}];
          return [4 /*yield*/, deserializeAws_json1_1ResourceNotFoundExceptionResponse(parsedOutput, context)];
        case 13:
          response = __assign.apply(void 0, [__assign.apply(void 0, _h.concat([_l.sent()])), {
            name: errorCode,
            $metadata: deserializeMetadata(output)
          }]);
          return [3 /*break*/, 17];
        case 14:
          _j = [{}];
          return [4 /*yield*/, deserializeAws_json1_1TooManyRequestsExceptionResponse(parsedOutput, context)];
        case 15:
          response = __assign.apply(void 0, [__assign.apply(void 0, _j.concat([_l.sent()])), {
            name: errorCode,
            $metadata: deserializeMetadata(output)
          }]);
          return [3 /*break*/, 17];
        case 16:
          parsedBody = parsedOutput.body;
          errorCode = parsedBody.code || parsedBody.Code || errorCode;
          response = __assign(__assign({}, parsedBody), {
            name: "" + errorCode,
            message: parsedBody.message || parsedBody.Message || errorCode,
            $fault: "client",
            $metadata: deserializeMetadata(output)
          });
          _l.label = 17;
        case 17:
          message = response.message || response.Message || errorCode;
          response.message = message;
          delete response.Message;
          return [2 /*return*/, Promise.reject(Object.assign(new Error(message), response))];
      }
    });
  });
};
export var deserializeAws_json1_1GetOpenIdTokenForDeveloperIdentityCommand = function (output, context) {
  return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          if (output.statusCode >= 300) {
            return [2 /*return*/, deserializeAws_json1_1GetOpenIdTokenForDeveloperIdentityCommandError(output, context)];
          }
          return [4 /*yield*/, parseBody(output.body, context)];
        case 1:
          data = _a.sent();
          contents = {};
          contents = deserializeAws_json1_1GetOpenIdTokenForDeveloperIdentityResponse(data, context);
          response = __assign({
            $metadata: deserializeMetadata(output)
          }, contents);
          return [2 /*return*/, Promise.resolve(response)];
      }
    });
  });
};
var deserializeAws_json1_1GetOpenIdTokenForDeveloperIdentityCommandError = function (output, context) {
  return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, _f, _g, _h, _j, parsedBody, message;
    var _k;
    return __generator(this, function (_l) {
      switch (_l.label) {
        case 0:
          _a = [__assign({}, output)];
          _k = {};
          return [4 /*yield*/, parseBody(output.body, context)];
        case 1:
          parsedOutput = __assign.apply(void 0, _a.concat([(_k.body = _l.sent(), _k)]));
          errorCode = "UnknownError";
          errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
          _b = errorCode;
          switch (_b) {
            case "DeveloperUserAlreadyRegisteredException":
              return [3 /*break*/, 2];
            case "com.amazonaws.cognitoidentity#DeveloperUserAlreadyRegisteredException":
              return [3 /*break*/, 2];
            case "InternalErrorException":
              return [3 /*break*/, 4];
            case "com.amazonaws.cognitoidentity#InternalErrorException":
              return [3 /*break*/, 4];
            case "InvalidParameterException":
              return [3 /*break*/, 6];
            case "com.amazonaws.cognitoidentity#InvalidParameterException":
              return [3 /*break*/, 6];
            case "NotAuthorizedException":
              return [3 /*break*/, 8];
            case "com.amazonaws.cognitoidentity#NotAuthorizedException":
              return [3 /*break*/, 8];
            case "ResourceConflictException":
              return [3 /*break*/, 10];
            case "com.amazonaws.cognitoidentity#ResourceConflictException":
              return [3 /*break*/, 10];
            case "ResourceNotFoundException":
              return [3 /*break*/, 12];
            case "com.amazonaws.cognitoidentity#ResourceNotFoundException":
              return [3 /*break*/, 12];
            case "TooManyRequestsException":
              return [3 /*break*/, 14];
            case "com.amazonaws.cognitoidentity#TooManyRequestsException":
              return [3 /*break*/, 14];
          }
          return [3 /*break*/, 16];
        case 2:
          _c = [{}];
          return [4 /*yield*/, deserializeAws_json1_1DeveloperUserAlreadyRegisteredExceptionResponse(parsedOutput, context)];
        case 3:
          response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([_l.sent()])), {
            name: errorCode,
            $metadata: deserializeMetadata(output)
          }]);
          return [3 /*break*/, 17];
        case 4:
          _d = [{}];
          return [4 /*yield*/, deserializeAws_json1_1InternalErrorExceptionResponse(parsedOutput, context)];
        case 5:
          response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([_l.sent()])), {
            name: errorCode,
            $metadata: deserializeMetadata(output)
          }]);
          return [3 /*break*/, 17];
        case 6:
          _e = [{}];
          return [4 /*yield*/, deserializeAws_json1_1InvalidParameterExceptionResponse(parsedOutput, context)];
        case 7:
          response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([_l.sent()])), {
            name: errorCode,
            $metadata: deserializeMetadata(output)
          }]);
          return [3 /*break*/, 17];
        case 8:
          _f = [{}];
          return [4 /*yield*/, deserializeAws_json1_1NotAuthorizedExceptionResponse(parsedOutput, context)];
        case 9:
          response = __assign.apply(void 0, [__assign.apply(void 0, _f.concat([_l.sent()])), {
            name: errorCode,
            $metadata: deserializeMetadata(output)
          }]);
          return [3 /*break*/, 17];
        case 10:
          _g = [{}];
          return [4 /*yield*/, deserializeAws_json1_1ResourceConflictExceptionResponse(parsedOutput, context)];
        case 11:
          response = __assign.apply(void 0, [__assign.apply(void 0, _g.concat([_l.sent()])), {
            name: errorCode,
            $metadata: deserializeMetadata(output)
          }]);
          return [3 /*break*/, 17];
        case 12:
          _h = [{}];
          return [4 /*yield*/, deserializeAws_json1_1ResourceNotFoundExceptionResponse(parsedOutput, context)];
        case 13:
          response = __assign.apply(void 0, [__assign.apply(void 0, _h.concat([_l.sent()])), {
            name: errorCode,
            $metadata: deserializeMetadata(output)
          }]);
          return [3 /*break*/, 17];
        case 14:
          _j = [{}];
          return [4 /*yield*/, deserializeAws_json1_1TooManyRequestsExceptionResponse(parsedOutput, context)];
        case 15:
          response = __assign.apply(void 0, [__assign.apply(void 0, _j.concat([_l.sent()])), {
            name: errorCode,
            $metadata: deserializeMetadata(output)
          }]);
          return [3 /*break*/, 17];
        case 16:
          parsedBody = parsedOutput.body;
          errorCode = parsedBody.code || parsedBody.Code || errorCode;
          response = __assign(__assign({}, parsedBody), {
            name: "" + errorCode,
            message: parsedBody.message || parsedBody.Message || errorCode,
            $fault: "client",
            $metadata: deserializeMetadata(output)
          });
          _l.label = 17;
        case 17:
          message = response.message || response.Message || errorCode;
          response.message = message;
          delete response.Message;
          return [2 /*return*/, Promise.reject(Object.assign(new Error(message), response))];
      }
    });
  });
};
export var deserializeAws_json1_1ListIdentitiesCommand = function (output, context) {
  return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          if (output.statusCode >= 300) {
            return [2 /*return*/, deserializeAws_json1_1ListIdentitiesCommandError(output, context)];
          }
          return [4 /*yield*/, parseBody(output.body, context)];
        case 1:
          data = _a.sent();
          contents = {};
          contents = deserializeAws_json1_1ListIdentitiesResponse(data, context);
          response = __assign({
            $metadata: deserializeMetadata(output)
          }, contents);
          return [2 /*return*/, Promise.resolve(response)];
      }
    });
  });
};
var deserializeAws_json1_1ListIdentitiesCommandError = function (output, context) {
  return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, _f, _g, parsedBody, message;
    var _h;
    return __generator(this, function (_j) {
      switch (_j.label) {
        case 0:
          _a = [__assign({}, output)];
          _h = {};
          return [4 /*yield*/, parseBody(output.body, context)];
        case 1:
          parsedOutput = __assign.apply(void 0, _a.concat([(_h.body = _j.sent(), _h)]));
          errorCode = "UnknownError";
          errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
          _b = errorCode;
          switch (_b) {
            case "InternalErrorException":
              return [3 /*break*/, 2];
            case "com.amazonaws.cognitoidentity#InternalErrorException":
              return [3 /*break*/, 2];
            case "InvalidParameterException":
              return [3 /*break*/, 4];
            case "com.amazonaws.cognitoidentity#InvalidParameterException":
              return [3 /*break*/, 4];
            case "NotAuthorizedException":
              return [3 /*break*/, 6];
            case "com.amazonaws.cognitoidentity#NotAuthorizedException":
              return [3 /*break*/, 6];
            case "ResourceNotFoundException":
              return [3 /*break*/, 8];
            case "com.amazonaws.cognitoidentity#ResourceNotFoundException":
              return [3 /*break*/, 8];
            case "TooManyRequestsException":
              return [3 /*break*/, 10];
            case "com.amazonaws.cognitoidentity#TooManyRequestsException":
              return [3 /*break*/, 10];
          }
          return [3 /*break*/, 12];
        case 2:
          _c = [{}];
          return [4 /*yield*/, deserializeAws_json1_1InternalErrorExceptionResponse(parsedOutput, context)];
        case 3:
          response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([_j.sent()])), {
            name: errorCode,
            $metadata: deserializeMetadata(output)
          }]);
          return [3 /*break*/, 13];
        case 4:
          _d = [{}];
          return [4 /*yield*/, deserializeAws_json1_1InvalidParameterExceptionResponse(parsedOutput, context)];
        case 5:
          response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([_j.sent()])), {
            name: errorCode,
            $metadata: deserializeMetadata(output)
          }]);
          return [3 /*break*/, 13];
        case 6:
          _e = [{}];
          return [4 /*yield*/, deserializeAws_json1_1NotAuthorizedExceptionResponse(parsedOutput, context)];
        case 7:
          response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([_j.sent()])), {
            name: errorCode,
            $metadata: deserializeMetadata(output)
          }]);
          return [3 /*break*/, 13];
        case 8:
          _f = [{}];
          return [4 /*yield*/, deserializeAws_json1_1ResourceNotFoundExceptionResponse(parsedOutput, context)];
        case 9:
          response = __assign.apply(void 0, [__assign.apply(void 0, _f.concat([_j.sent()])), {
            name: errorCode,
            $metadata: deserializeMetadata(output)
          }]);
          return [3 /*break*/, 13];
        case 10:
          _g = [{}];
          return [4 /*yield*/, deserializeAws_json1_1TooManyRequestsExceptionResponse(parsedOutput, context)];
        case 11:
          response = __assign.apply(void 0, [__assign.apply(void 0, _g.concat([_j.sent()])), {
            name: errorCode,
            $metadata: deserializeMetadata(output)
          }]);
          return [3 /*break*/, 13];
        case 12:
          parsedBody = parsedOutput.body;
          errorCode = parsedBody.code || parsedBody.Code || errorCode;
          response = __assign(__assign({}, parsedBody), {
            name: "" + errorCode,
            message: parsedBody.message || parsedBody.Message || errorCode,
            $fault: "client",
            $metadata: deserializeMetadata(output)
          });
          _j.label = 13;
        case 13:
          message = response.message || response.Message || errorCode;
          response.message = message;
          delete response.Message;
          return [2 /*return*/, Promise.reject(Object.assign(new Error(message), response))];
      }
    });
  });
};
export var deserializeAws_json1_1ListIdentityPoolsCommand = function (output, context) {
  return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          if (output.statusCode >= 300) {
            return [2 /*return*/, deserializeAws_json1_1ListIdentityPoolsCommandError(output, context)];
          }
          return [4 /*yield*/, parseBody(output.body, context)];
        case 1:
          data = _a.sent();
          contents = {};
          contents = deserializeAws_json1_1ListIdentityPoolsResponse(data, context);
          response = __assign({
            $metadata: deserializeMetadata(output)
          }, contents);
          return [2 /*return*/, Promise.resolve(response)];
      }
    });
  });
};
var deserializeAws_json1_1ListIdentityPoolsCommandError = function (output, context) {
  return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, _f, _g, parsedBody, message;
    var _h;
    return __generator(this, function (_j) {
      switch (_j.label) {
        case 0:
          _a = [__assign({}, output)];
          _h = {};
          return [4 /*yield*/, parseBody(output.body, context)];
        case 1:
          parsedOutput = __assign.apply(void 0, _a.concat([(_h.body = _j.sent(), _h)]));
          errorCode = "UnknownError";
          errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
          _b = errorCode;
          switch (_b) {
            case "InternalErrorException":
              return [3 /*break*/, 2];
            case "com.amazonaws.cognitoidentity#InternalErrorException":
              return [3 /*break*/, 2];
            case "InvalidParameterException":
              return [3 /*break*/, 4];
            case "com.amazonaws.cognitoidentity#InvalidParameterException":
              return [3 /*break*/, 4];
            case "NotAuthorizedException":
              return [3 /*break*/, 6];
            case "com.amazonaws.cognitoidentity#NotAuthorizedException":
              return [3 /*break*/, 6];
            case "ResourceNotFoundException":
              return [3 /*break*/, 8];
            case "com.amazonaws.cognitoidentity#ResourceNotFoundException":
              return [3 /*break*/, 8];
            case "TooManyRequestsException":
              return [3 /*break*/, 10];
            case "com.amazonaws.cognitoidentity#TooManyRequestsException":
              return [3 /*break*/, 10];
          }
          return [3 /*break*/, 12];
        case 2:
          _c = [{}];
          return [4 /*yield*/, deserializeAws_json1_1InternalErrorExceptionResponse(parsedOutput, context)];
        case 3:
          response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([_j.sent()])), {
            name: errorCode,
            $metadata: deserializeMetadata(output)
          }]);
          return [3 /*break*/, 13];
        case 4:
          _d = [{}];
          return [4 /*yield*/, deserializeAws_json1_1InvalidParameterExceptionResponse(parsedOutput, context)];
        case 5:
          response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([_j.sent()])), {
            name: errorCode,
            $metadata: deserializeMetadata(output)
          }]);
          return [3 /*break*/, 13];
        case 6:
          _e = [{}];
          return [4 /*yield*/, deserializeAws_json1_1NotAuthorizedExceptionResponse(parsedOutput, context)];
        case 7:
          response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([_j.sent()])), {
            name: errorCode,
            $metadata: deserializeMetadata(output)
          }]);
          return [3 /*break*/, 13];
        case 8:
          _f = [{}];
          return [4 /*yield*/, deserializeAws_json1_1ResourceNotFoundExceptionResponse(parsedOutput, context)];
        case 9:
          response = __assign.apply(void 0, [__assign.apply(void 0, _f.concat([_j.sent()])), {
            name: errorCode,
            $metadata: deserializeMetadata(output)
          }]);
          return [3 /*break*/, 13];
        case 10:
          _g = [{}];
          return [4 /*yield*/, deserializeAws_json1_1TooManyRequestsExceptionResponse(parsedOutput, context)];
        case 11:
          response = __assign.apply(void 0, [__assign.apply(void 0, _g.concat([_j.sent()])), {
            name: errorCode,
            $metadata: deserializeMetadata(output)
          }]);
          return [3 /*break*/, 13];
        case 12:
          parsedBody = parsedOutput.body;
          errorCode = parsedBody.code || parsedBody.Code || errorCode;
          response = __assign(__assign({}, parsedBody), {
            name: "" + errorCode,
            message: parsedBody.message || parsedBody.Message || errorCode,
            $fault: "client",
            $metadata: deserializeMetadata(output)
          });
          _j.label = 13;
        case 13:
          message = response.message || response.Message || errorCode;
          response.message = message;
          delete response.Message;
          return [2 /*return*/, Promise.reject(Object.assign(new Error(message), response))];
      }
    });
  });
};
export var deserializeAws_json1_1ListTagsForResourceCommand = function (output, context) {
  return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          if (output.statusCode >= 300) {
            return [2 /*return*/, deserializeAws_json1_1ListTagsForResourceCommandError(output, context)];
          }
          return [4 /*yield*/, parseBody(output.body, context)];
        case 1:
          data = _a.sent();
          contents = {};
          contents = deserializeAws_json1_1ListTagsForResourceResponse(data, context);
          response = __assign({
            $metadata: deserializeMetadata(output)
          }, contents);
          return [2 /*return*/, Promise.resolve(response)];
      }
    });
  });
};
var deserializeAws_json1_1ListTagsForResourceCommandError = function (output, context) {
  return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, _f, _g, parsedBody, message;
    var _h;
    return __generator(this, function (_j) {
      switch (_j.label) {
        case 0:
          _a = [__assign({}, output)];
          _h = {};
          return [4 /*yield*/, parseBody(output.body, context)];
        case 1:
          parsedOutput = __assign.apply(void 0, _a.concat([(_h.body = _j.sent(), _h)]));
          errorCode = "UnknownError";
          errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
          _b = errorCode;
          switch (_b) {
            case "InternalErrorException":
              return [3 /*break*/, 2];
            case "com.amazonaws.cognitoidentity#InternalErrorException":
              return [3 /*break*/, 2];
            case "InvalidParameterException":
              return [3 /*break*/, 4];
            case "com.amazonaws.cognitoidentity#InvalidParameterException":
              return [3 /*break*/, 4];
            case "NotAuthorizedException":
              return [3 /*break*/, 6];
            case "com.amazonaws.cognitoidentity#NotAuthorizedException":
              return [3 /*break*/, 6];
            case "ResourceNotFoundException":
              return [3 /*break*/, 8];
            case "com.amazonaws.cognitoidentity#ResourceNotFoundException":
              return [3 /*break*/, 8];
            case "TooManyRequestsException":
              return [3 /*break*/, 10];
            case "com.amazonaws.cognitoidentity#TooManyRequestsException":
              return [3 /*break*/, 10];
          }
          return [3 /*break*/, 12];
        case 2:
          _c = [{}];
          return [4 /*yield*/, deserializeAws_json1_1InternalErrorExceptionResponse(parsedOutput, context)];
        case 3:
          response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([_j.sent()])), {
            name: errorCode,
            $metadata: deserializeMetadata(output)
          }]);
          return [3 /*break*/, 13];
        case 4:
          _d = [{}];
          return [4 /*yield*/, deserializeAws_json1_1InvalidParameterExceptionResponse(parsedOutput, context)];
        case 5:
          response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([_j.sent()])), {
            name: errorCode,
            $metadata: deserializeMetadata(output)
          }]);
          return [3 /*break*/, 13];
        case 6:
          _e = [{}];
          return [4 /*yield*/, deserializeAws_json1_1NotAuthorizedExceptionResponse(parsedOutput, context)];
        case 7:
          response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([_j.sent()])), {
            name: errorCode,
            $metadata: deserializeMetadata(output)
          }]);
          return [3 /*break*/, 13];
        case 8:
          _f = [{}];
          return [4 /*yield*/, deserializeAws_json1_1ResourceNotFoundExceptionResponse(parsedOutput, context)];
        case 9:
          response = __assign.apply(void 0, [__assign.apply(void 0, _f.concat([_j.sent()])), {
            name: errorCode,
            $metadata: deserializeMetadata(output)
          }]);
          return [3 /*break*/, 13];
        case 10:
          _g = [{}];
          return [4 /*yield*/, deserializeAws_json1_1TooManyRequestsExceptionResponse(parsedOutput, context)];
        case 11:
          response = __assign.apply(void 0, [__assign.apply(void 0, _g.concat([_j.sent()])), {
            name: errorCode,
            $metadata: deserializeMetadata(output)
          }]);
          return [3 /*break*/, 13];
        case 12:
          parsedBody = parsedOutput.body;
          errorCode = parsedBody.code || parsedBody.Code || errorCode;
          response = __assign(__assign({}, parsedBody), {
            name: "" + errorCode,
            message: parsedBody.message || parsedBody.Message || errorCode,
            $fault: "client",
            $metadata: deserializeMetadata(output)
          });
          _j.label = 13;
        case 13:
          message = response.message || response.Message || errorCode;
          response.message = message;
          delete response.Message;
          return [2 /*return*/, Promise.reject(Object.assign(new Error(message), response))];
      }
    });
  });
};
export var deserializeAws_json1_1LookupDeveloperIdentityCommand = function (output, context) {
  return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          if (output.statusCode >= 300) {
            return [2 /*return*/, deserializeAws_json1_1LookupDeveloperIdentityCommandError(output, context)];
          }
          return [4 /*yield*/, parseBody(output.body, context)];
        case 1:
          data = _a.sent();
          contents = {};
          contents = deserializeAws_json1_1LookupDeveloperIdentityResponse(data, context);
          response = __assign({
            $metadata: deserializeMetadata(output)
          }, contents);
          return [2 /*return*/, Promise.resolve(response)];
      }
    });
  });
};
var deserializeAws_json1_1LookupDeveloperIdentityCommandError = function (output, context) {
  return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, _f, _g, _h, parsedBody, message;
    var _j;
    return __generator(this, function (_k) {
      switch (_k.label) {
        case 0:
          _a = [__assign({}, output)];
          _j = {};
          return [4 /*yield*/, parseBody(output.body, context)];
        case 1:
          parsedOutput = __assign.apply(void 0, _a.concat([(_j.body = _k.sent(), _j)]));
          errorCode = "UnknownError";
          errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
          _b = errorCode;
          switch (_b) {
            case "InternalErrorException":
              return [3 /*break*/, 2];
            case "com.amazonaws.cognitoidentity#InternalErrorException":
              return [3 /*break*/, 2];
            case "InvalidParameterException":
              return [3 /*break*/, 4];
            case "com.amazonaws.cognitoidentity#InvalidParameterException":
              return [3 /*break*/, 4];
            case "NotAuthorizedException":
              return [3 /*break*/, 6];
            case "com.amazonaws.cognitoidentity#NotAuthorizedException":
              return [3 /*break*/, 6];
            case "ResourceConflictException":
              return [3 /*break*/, 8];
            case "com.amazonaws.cognitoidentity#ResourceConflictException":
              return [3 /*break*/, 8];
            case "ResourceNotFoundException":
              return [3 /*break*/, 10];
            case "com.amazonaws.cognitoidentity#ResourceNotFoundException":
              return [3 /*break*/, 10];
            case "TooManyRequestsException":
              return [3 /*break*/, 12];
            case "com.amazonaws.cognitoidentity#TooManyRequestsException":
              return [3 /*break*/, 12];
          }
          return [3 /*break*/, 14];
        case 2:
          _c = [{}];
          return [4 /*yield*/, deserializeAws_json1_1InternalErrorExceptionResponse(parsedOutput, context)];
        case 3:
          response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([_k.sent()])), {
            name: errorCode,
            $metadata: deserializeMetadata(output)
          }]);
          return [3 /*break*/, 15];
        case 4:
          _d = [{}];
          return [4 /*yield*/, deserializeAws_json1_1InvalidParameterExceptionResponse(parsedOutput, context)];
        case 5:
          response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([_k.sent()])), {
            name: errorCode,
            $metadata: deserializeMetadata(output)
          }]);
          return [3 /*break*/, 15];
        case 6:
          _e = [{}];
          return [4 /*yield*/, deserializeAws_json1_1NotAuthorizedExceptionResponse(parsedOutput, context)];
        case 7:
          response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([_k.sent()])), {
            name: errorCode,
            $metadata: deserializeMetadata(output)
          }]);
          return [3 /*break*/, 15];
        case 8:
          _f = [{}];
          return [4 /*yield*/, deserializeAws_json1_1ResourceConflictExceptionResponse(parsedOutput, context)];
        case 9:
          response = __assign.apply(void 0, [__assign.apply(void 0, _f.concat([_k.sent()])), {
            name: errorCode,
            $metadata: deserializeMetadata(output)
          }]);
          return [3 /*break*/, 15];
        case 10:
          _g = [{}];
          return [4 /*yield*/, deserializeAws_json1_1ResourceNotFoundExceptionResponse(parsedOutput, context)];
        case 11:
          response = __assign.apply(void 0, [__assign.apply(void 0, _g.concat([_k.sent()])), {
            name: errorCode,
            $metadata: deserializeMetadata(output)
          }]);
          return [3 /*break*/, 15];
        case 12:
          _h = [{}];
          return [4 /*yield*/, deserializeAws_json1_1TooManyRequestsExceptionResponse(parsedOutput, context)];
        case 13:
          response = __assign.apply(void 0, [__assign.apply(void 0, _h.concat([_k.sent()])), {
            name: errorCode,
            $metadata: deserializeMetadata(output)
          }]);
          return [3 /*break*/, 15];
        case 14:
          parsedBody = parsedOutput.body;
          errorCode = parsedBody.code || parsedBody.Code || errorCode;
          response = __assign(__assign({}, parsedBody), {
            name: "" + errorCode,
            message: parsedBody.message || parsedBody.Message || errorCode,
            $fault: "client",
            $metadata: deserializeMetadata(output)
          });
          _k.label = 15;
        case 15:
          message = response.message || response.Message || errorCode;
          response.message = message;
          delete response.Message;
          return [2 /*return*/, Promise.reject(Object.assign(new Error(message), response))];
      }
    });
  });
};
export var deserializeAws_json1_1MergeDeveloperIdentitiesCommand = function (output, context) {
  return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          if (output.statusCode >= 300) {
            return [2 /*return*/, deserializeAws_json1_1MergeDeveloperIdentitiesCommandError(output, context)];
          }
          return [4 /*yield*/, parseBody(output.body, context)];
        case 1:
          data = _a.sent();
          contents = {};
          contents = deserializeAws_json1_1MergeDeveloperIdentitiesResponse(data, context);
          response = __assign({
            $metadata: deserializeMetadata(output)
          }, contents);
          return [2 /*return*/, Promise.resolve(response)];
      }
    });
  });
};
var deserializeAws_json1_1MergeDeveloperIdentitiesCommandError = function (output, context) {
  return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, _f, _g, _h, parsedBody, message;
    var _j;
    return __generator(this, function (_k) {
      switch (_k.label) {
        case 0:
          _a = [__assign({}, output)];
          _j = {};
          return [4 /*yield*/, parseBody(output.body, context)];
        case 1:
          parsedOutput = __assign.apply(void 0, _a.concat([(_j.body = _k.sent(), _j)]));
          errorCode = "UnknownError";
          errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
          _b = errorCode;
          switch (_b) {
            case "InternalErrorException":
              return [3 /*break*/, 2];
            case "com.amazonaws.cognitoidentity#InternalErrorException":
              return [3 /*break*/, 2];
            case "InvalidParameterException":
              return [3 /*break*/, 4];
            case "com.amazonaws.cognitoidentity#InvalidParameterException":
              return [3 /*break*/, 4];
            case "NotAuthorizedException":
              return [3 /*break*/, 6];
            case "com.amazonaws.cognitoidentity#NotAuthorizedException":
              return [3 /*break*/, 6];
            case "ResourceConflictException":
              return [3 /*break*/, 8];
            case "com.amazonaws.cognitoidentity#ResourceConflictException":
              return [3 /*break*/, 8];
            case "ResourceNotFoundException":
              return [3 /*break*/, 10];
            case "com.amazonaws.cognitoidentity#ResourceNotFoundException":
              return [3 /*break*/, 10];
            case "TooManyRequestsException":
              return [3 /*break*/, 12];
            case "com.amazonaws.cognitoidentity#TooManyRequestsException":
              return [3 /*break*/, 12];
          }
          return [3 /*break*/, 14];
        case 2:
          _c = [{}];
          return [4 /*yield*/, deserializeAws_json1_1InternalErrorExceptionResponse(parsedOutput, context)];
        case 3:
          response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([_k.sent()])), {
            name: errorCode,
            $metadata: deserializeMetadata(output)
          }]);
          return [3 /*break*/, 15];
        case 4:
          _d = [{}];
          return [4 /*yield*/, deserializeAws_json1_1InvalidParameterExceptionResponse(parsedOutput, context)];
        case 5:
          response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([_k.sent()])), {
            name: errorCode,
            $metadata: deserializeMetadata(output)
          }]);
          return [3 /*break*/, 15];
        case 6:
          _e = [{}];
          return [4 /*yield*/, deserializeAws_json1_1NotAuthorizedExceptionResponse(parsedOutput, context)];
        case 7:
          response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([_k.sent()])), {
            name: errorCode,
            $metadata: deserializeMetadata(output)
          }]);
          return [3 /*break*/, 15];
        case 8:
          _f = [{}];
          return [4 /*yield*/, deserializeAws_json1_1ResourceConflictExceptionResponse(parsedOutput, context)];
        case 9:
          response = __assign.apply(void 0, [__assign.apply(void 0, _f.concat([_k.sent()])), {
            name: errorCode,
            $metadata: deserializeMetadata(output)
          }]);
          return [3 /*break*/, 15];
        case 10:
          _g = [{}];
          return [4 /*yield*/, deserializeAws_json1_1ResourceNotFoundExceptionResponse(parsedOutput, context)];
        case 11:
          response = __assign.apply(void 0, [__assign.apply(void 0, _g.concat([_k.sent()])), {
            name: errorCode,
            $metadata: deserializeMetadata(output)
          }]);
          return [3 /*break*/, 15];
        case 12:
          _h = [{}];
          return [4 /*yield*/, deserializeAws_json1_1TooManyRequestsExceptionResponse(parsedOutput, context)];
        case 13:
          response = __assign.apply(void 0, [__assign.apply(void 0, _h.concat([_k.sent()])), {
            name: errorCode,
            $metadata: deserializeMetadata(output)
          }]);
          return [3 /*break*/, 15];
        case 14:
          parsedBody = parsedOutput.body;
          errorCode = parsedBody.code || parsedBody.Code || errorCode;
          response = __assign(__assign({}, parsedBody), {
            name: "" + errorCode,
            message: parsedBody.message || parsedBody.Message || errorCode,
            $fault: "client",
            $metadata: deserializeMetadata(output)
          });
          _k.label = 15;
        case 15:
          message = response.message || response.Message || errorCode;
          response.message = message;
          delete response.Message;
          return [2 /*return*/, Promise.reject(Object.assign(new Error(message), response))];
      }
    });
  });
};
export var deserializeAws_json1_1SetIdentityPoolRolesCommand = function (output, context) {
  return __awaiter(void 0, void 0, void 0, function () {
    var response;
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          if (output.statusCode >= 300) {
            return [2 /*return*/, deserializeAws_json1_1SetIdentityPoolRolesCommandError(output, context)];
          }
          return [4 /*yield*/, collectBody(output.body, context)];
        case 1:
          _a.sent();
          response = {
            $metadata: deserializeMetadata(output)
          };
          return [2 /*return*/, Promise.resolve(response)];
      }
    });
  });
};
var deserializeAws_json1_1SetIdentityPoolRolesCommandError = function (output, context) {
  return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, _f, _g, _h, _j, parsedBody, message;
    var _k;
    return __generator(this, function (_l) {
      switch (_l.label) {
        case 0:
          _a = [__assign({}, output)];
          _k = {};
          return [4 /*yield*/, parseBody(output.body, context)];
        case 1:
          parsedOutput = __assign.apply(void 0, _a.concat([(_k.body = _l.sent(), _k)]));
          errorCode = "UnknownError";
          errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
          _b = errorCode;
          switch (_b) {
            case "ConcurrentModificationException":
              return [3 /*break*/, 2];
            case "com.amazonaws.cognitoidentity#ConcurrentModificationException":
              return [3 /*break*/, 2];
            case "InternalErrorException":
              return [3 /*break*/, 4];
            case "com.amazonaws.cognitoidentity#InternalErrorException":
              return [3 /*break*/, 4];
            case "InvalidParameterException":
              return [3 /*break*/, 6];
            case "com.amazonaws.cognitoidentity#InvalidParameterException":
              return [3 /*break*/, 6];
            case "NotAuthorizedException":
              return [3 /*break*/, 8];
            case "com.amazonaws.cognitoidentity#NotAuthorizedException":
              return [3 /*break*/, 8];
            case "ResourceConflictException":
              return [3 /*break*/, 10];
            case "com.amazonaws.cognitoidentity#ResourceConflictException":
              return [3 /*break*/, 10];
            case "ResourceNotFoundException":
              return [3 /*break*/, 12];
            case "com.amazonaws.cognitoidentity#ResourceNotFoundException":
              return [3 /*break*/, 12];
            case "TooManyRequestsException":
              return [3 /*break*/, 14];
            case "com.amazonaws.cognitoidentity#TooManyRequestsException":
              return [3 /*break*/, 14];
          }
          return [3 /*break*/, 16];
        case 2:
          _c = [{}];
          return [4 /*yield*/, deserializeAws_json1_1ConcurrentModificationExceptionResponse(parsedOutput, context)];
        case 3:
          response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([_l.sent()])), {
            name: errorCode,
            $metadata: deserializeMetadata(output)
          }]);
          return [3 /*break*/, 17];
        case 4:
          _d = [{}];
          return [4 /*yield*/, deserializeAws_json1_1InternalErrorExceptionResponse(parsedOutput, context)];
        case 5:
          response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([_l.sent()])), {
            name: errorCode,
            $metadata: deserializeMetadata(output)
          }]);
          return [3 /*break*/, 17];
        case 6:
          _e = [{}];
          return [4 /*yield*/, deserializeAws_json1_1InvalidParameterExceptionResponse(parsedOutput, context)];
        case 7:
          response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([_l.sent()])), {
            name: errorCode,
            $metadata: deserializeMetadata(output)
          }]);
          return [3 /*break*/, 17];
        case 8:
          _f = [{}];
          return [4 /*yield*/, deserializeAws_json1_1NotAuthorizedExceptionResponse(parsedOutput, context)];
        case 9:
          response = __assign.apply(void 0, [__assign.apply(void 0, _f.concat([_l.sent()])), {
            name: errorCode,
            $metadata: deserializeMetadata(output)
          }]);
          return [3 /*break*/, 17];
        case 10:
          _g = [{}];
          return [4 /*yield*/, deserializeAws_json1_1ResourceConflictExceptionResponse(parsedOutput, context)];
        case 11:
          response = __assign.apply(void 0, [__assign.apply(void 0, _g.concat([_l.sent()])), {
            name: errorCode,
            $metadata: deserializeMetadata(output)
          }]);
          return [3 /*break*/, 17];
        case 12:
          _h = [{}];
          return [4 /*yield*/, deserializeAws_json1_1ResourceNotFoundExceptionResponse(parsedOutput, context)];
        case 13:
          response = __assign.apply(void 0, [__assign.apply(void 0, _h.concat([_l.sent()])), {
            name: errorCode,
            $metadata: deserializeMetadata(output)
          }]);
          return [3 /*break*/, 17];
        case 14:
          _j = [{}];
          return [4 /*yield*/, deserializeAws_json1_1TooManyRequestsExceptionResponse(parsedOutput, context)];
        case 15:
          response = __assign.apply(void 0, [__assign.apply(void 0, _j.concat([_l.sent()])), {
            name: errorCode,
            $metadata: deserializeMetadata(output)
          }]);
          return [3 /*break*/, 17];
        case 16:
          parsedBody = parsedOutput.body;
          errorCode = parsedBody.code || parsedBody.Code || errorCode;
          response = __assign(__assign({}, parsedBody), {
            name: "" + errorCode,
            message: parsedBody.message || parsedBody.Message || errorCode,
            $fault: "client",
            $metadata: deserializeMetadata(output)
          });
          _l.label = 17;
        case 17:
          message = response.message || response.Message || errorCode;
          response.message = message;
          delete response.Message;
          return [2 /*return*/, Promise.reject(Object.assign(new Error(message), response))];
      }
    });
  });
};
export var deserializeAws_json1_1TagResourceCommand = function (output, context) {
  return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          if (output.statusCode >= 300) {
            return [2 /*return*/, deserializeAws_json1_1TagResourceCommandError(output, context)];
          }
          return [4 /*yield*/, parseBody(output.body, context)];
        case 1:
          data = _a.sent();
          contents = {};
          contents = deserializeAws_json1_1TagResourceResponse(data, context);
          response = __assign({
            $metadata: deserializeMetadata(output)
          }, contents);
          return [2 /*return*/, Promise.resolve(response)];
      }
    });
  });
};
var deserializeAws_json1_1TagResourceCommandError = function (output, context) {
  return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, _f, _g, parsedBody, message;
    var _h;
    return __generator(this, function (_j) {
      switch (_j.label) {
        case 0:
          _a = [__assign({}, output)];
          _h = {};
          return [4 /*yield*/, parseBody(output.body, context)];
        case 1:
          parsedOutput = __assign.apply(void 0, _a.concat([(_h.body = _j.sent(), _h)]));
          errorCode = "UnknownError";
          errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
          _b = errorCode;
          switch (_b) {
            case "InternalErrorException":
              return [3 /*break*/, 2];
            case "com.amazonaws.cognitoidentity#InternalErrorException":
              return [3 /*break*/, 2];
            case "InvalidParameterException":
              return [3 /*break*/, 4];
            case "com.amazonaws.cognitoidentity#InvalidParameterException":
              return [3 /*break*/, 4];
            case "NotAuthorizedException":
              return [3 /*break*/, 6];
            case "com.amazonaws.cognitoidentity#NotAuthorizedException":
              return [3 /*break*/, 6];
            case "ResourceNotFoundException":
              return [3 /*break*/, 8];
            case "com.amazonaws.cognitoidentity#ResourceNotFoundException":
              return [3 /*break*/, 8];
            case "TooManyRequestsException":
              return [3 /*break*/, 10];
            case "com.amazonaws.cognitoidentity#TooManyRequestsException":
              return [3 /*break*/, 10];
          }
          return [3 /*break*/, 12];
        case 2:
          _c = [{}];
          return [4 /*yield*/, deserializeAws_json1_1InternalErrorExceptionResponse(parsedOutput, context)];
        case 3:
          response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([_j.sent()])), {
            name: errorCode,
            $metadata: deserializeMetadata(output)
          }]);
          return [3 /*break*/, 13];
        case 4:
          _d = [{}];
          return [4 /*yield*/, deserializeAws_json1_1InvalidParameterExceptionResponse(parsedOutput, context)];
        case 5:
          response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([_j.sent()])), {
            name: errorCode,
            $metadata: deserializeMetadata(output)
          }]);
          return [3 /*break*/, 13];
        case 6:
          _e = [{}];
          return [4 /*yield*/, deserializeAws_json1_1NotAuthorizedExceptionResponse(parsedOutput, context)];
        case 7:
          response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([_j.sent()])), {
            name: errorCode,
            $metadata: deserializeMetadata(output)
          }]);
          return [3 /*break*/, 13];
        case 8:
          _f = [{}];
          return [4 /*yield*/, deserializeAws_json1_1ResourceNotFoundExceptionResponse(parsedOutput, context)];
        case 9:
          response = __assign.apply(void 0, [__assign.apply(void 0, _f.concat([_j.sent()])), {
            name: errorCode,
            $metadata: deserializeMetadata(output)
          }]);
          return [3 /*break*/, 13];
        case 10:
          _g = [{}];
          return [4 /*yield*/, deserializeAws_json1_1TooManyRequestsExceptionResponse(parsedOutput, context)];
        case 11:
          response = __assign.apply(void 0, [__assign.apply(void 0, _g.concat([_j.sent()])), {
            name: errorCode,
            $metadata: deserializeMetadata(output)
          }]);
          return [3 /*break*/, 13];
        case 12:
          parsedBody = parsedOutput.body;
          errorCode = parsedBody.code || parsedBody.Code || errorCode;
          response = __assign(__assign({}, parsedBody), {
            name: "" + errorCode,
            message: parsedBody.message || parsedBody.Message || errorCode,
            $fault: "client",
            $metadata: deserializeMetadata(output)
          });
          _j.label = 13;
        case 13:
          message = response.message || response.Message || errorCode;
          response.message = message;
          delete response.Message;
          return [2 /*return*/, Promise.reject(Object.assign(new Error(message), response))];
      }
    });
  });
};
export var deserializeAws_json1_1UnlinkDeveloperIdentityCommand = function (output, context) {
  return __awaiter(void 0, void 0, void 0, function () {
    var response;
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          if (output.statusCode >= 300) {
            return [2 /*return*/, deserializeAws_json1_1UnlinkDeveloperIdentityCommandError(output, context)];
          }
          return [4 /*yield*/, collectBody(output.body, context)];
        case 1:
          _a.sent();
          response = {
            $metadata: deserializeMetadata(output)
          };
          return [2 /*return*/, Promise.resolve(response)];
      }
    });
  });
};
var deserializeAws_json1_1UnlinkDeveloperIdentityCommandError = function (output, context) {
  return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, _f, _g, _h, parsedBody, message;
    var _j;
    return __generator(this, function (_k) {
      switch (_k.label) {
        case 0:
          _a = [__assign({}, output)];
          _j = {};
          return [4 /*yield*/, parseBody(output.body, context)];
        case 1:
          parsedOutput = __assign.apply(void 0, _a.concat([(_j.body = _k.sent(), _j)]));
          errorCode = "UnknownError";
          errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
          _b = errorCode;
          switch (_b) {
            case "InternalErrorException":
              return [3 /*break*/, 2];
            case "com.amazonaws.cognitoidentity#InternalErrorException":
              return [3 /*break*/, 2];
            case "InvalidParameterException":
              return [3 /*break*/, 4];
            case "com.amazonaws.cognitoidentity#InvalidParameterException":
              return [3 /*break*/, 4];
            case "NotAuthorizedException":
              return [3 /*break*/, 6];
            case "com.amazonaws.cognitoidentity#NotAuthorizedException":
              return [3 /*break*/, 6];
            case "ResourceConflictException":
              return [3 /*break*/, 8];
            case "com.amazonaws.cognitoidentity#ResourceConflictException":
              return [3 /*break*/, 8];
            case "ResourceNotFoundException":
              return [3 /*break*/, 10];
            case "com.amazonaws.cognitoidentity#ResourceNotFoundException":
              return [3 /*break*/, 10];
            case "TooManyRequestsException":
              return [3 /*break*/, 12];
            case "com.amazonaws.cognitoidentity#TooManyRequestsException":
              return [3 /*break*/, 12];
          }
          return [3 /*break*/, 14];
        case 2:
          _c = [{}];
          return [4 /*yield*/, deserializeAws_json1_1InternalErrorExceptionResponse(parsedOutput, context)];
        case 3:
          response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([_k.sent()])), {
            name: errorCode,
            $metadata: deserializeMetadata(output)
          }]);
          return [3 /*break*/, 15];
        case 4:
          _d = [{}];
          return [4 /*yield*/, deserializeAws_json1_1InvalidParameterExceptionResponse(parsedOutput, context)];
        case 5:
          response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([_k.sent()])), {
            name: errorCode,
            $metadata: deserializeMetadata(output)
          }]);
          return [3 /*break*/, 15];
        case 6:
          _e = [{}];
          return [4 /*yield*/, deserializeAws_json1_1NotAuthorizedExceptionResponse(parsedOutput, context)];
        case 7:
          response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([_k.sent()])), {
            name: errorCode,
            $metadata: deserializeMetadata(output)
          }]);
          return [3 /*break*/, 15];
        case 8:
          _f = [{}];
          return [4 /*yield*/, deserializeAws_json1_1ResourceConflictExceptionResponse(parsedOutput, context)];
        case 9:
          response = __assign.apply(void 0, [__assign.apply(void 0, _f.concat([_k.sent()])), {
            name: errorCode,
            $metadata: deserializeMetadata(output)
          }]);
          return [3 /*break*/, 15];
        case 10:
          _g = [{}];
          return [4 /*yield*/, deserializeAws_json1_1ResourceNotFoundExceptionResponse(parsedOutput, context)];
        case 11:
          response = __assign.apply(void 0, [__assign.apply(void 0, _g.concat([_k.sent()])), {
            name: errorCode,
            $metadata: deserializeMetadata(output)
          }]);
          return [3 /*break*/, 15];
        case 12:
          _h = [{}];
          return [4 /*yield*/, deserializeAws_json1_1TooManyRequestsExceptionResponse(parsedOutput, context)];
        case 13:
          response = __assign.apply(void 0, [__assign.apply(void 0, _h.concat([_k.sent()])), {
            name: errorCode,
            $metadata: deserializeMetadata(output)
          }]);
          return [3 /*break*/, 15];
        case 14:
          parsedBody = parsedOutput.body;
          errorCode = parsedBody.code || parsedBody.Code || errorCode;
          response = __assign(__assign({}, parsedBody), {
            name: "" + errorCode,
            message: parsedBody.message || parsedBody.Message || errorCode,
            $fault: "client",
            $metadata: deserializeMetadata(output)
          });
          _k.label = 15;
        case 15:
          message = response.message || response.Message || errorCode;
          response.message = message;
          delete response.Message;
          return [2 /*return*/, Promise.reject(Object.assign(new Error(message), response))];
      }
    });
  });
};
export var deserializeAws_json1_1UnlinkIdentityCommand = function (output, context) {
  return __awaiter(void 0, void 0, void 0, function () {
    var response;
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          if (output.statusCode >= 300) {
            return [2 /*return*/, deserializeAws_json1_1UnlinkIdentityCommandError(output, context)];
          }
          return [4 /*yield*/, collectBody(output.body, context)];
        case 1:
          _a.sent();
          response = {
            $metadata: deserializeMetadata(output)
          };
          return [2 /*return*/, Promise.resolve(response)];
      }
    });
  });
};
var deserializeAws_json1_1UnlinkIdentityCommandError = function (output, context) {
  return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, _f, _g, _h, _j, parsedBody, message;
    var _k;
    return __generator(this, function (_l) {
      switch (_l.label) {
        case 0:
          _a = [__assign({}, output)];
          _k = {};
          return [4 /*yield*/, parseBody(output.body, context)];
        case 1:
          parsedOutput = __assign.apply(void 0, _a.concat([(_k.body = _l.sent(), _k)]));
          errorCode = "UnknownError";
          errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
          _b = errorCode;
          switch (_b) {
            case "ExternalServiceException":
              return [3 /*break*/, 2];
            case "com.amazonaws.cognitoidentity#ExternalServiceException":
              return [3 /*break*/, 2];
            case "InternalErrorException":
              return [3 /*break*/, 4];
            case "com.amazonaws.cognitoidentity#InternalErrorException":
              return [3 /*break*/, 4];
            case "InvalidParameterException":
              return [3 /*break*/, 6];
            case "com.amazonaws.cognitoidentity#InvalidParameterException":
              return [3 /*break*/, 6];
            case "NotAuthorizedException":
              return [3 /*break*/, 8];
            case "com.amazonaws.cognitoidentity#NotAuthorizedException":
              return [3 /*break*/, 8];
            case "ResourceConflictException":
              return [3 /*break*/, 10];
            case "com.amazonaws.cognitoidentity#ResourceConflictException":
              return [3 /*break*/, 10];
            case "ResourceNotFoundException":
              return [3 /*break*/, 12];
            case "com.amazonaws.cognitoidentity#ResourceNotFoundException":
              return [3 /*break*/, 12];
            case "TooManyRequestsException":
              return [3 /*break*/, 14];
            case "com.amazonaws.cognitoidentity#TooManyRequestsException":
              return [3 /*break*/, 14];
          }
          return [3 /*break*/, 16];
        case 2:
          _c = [{}];
          return [4 /*yield*/, deserializeAws_json1_1ExternalServiceExceptionResponse(parsedOutput, context)];
        case 3:
          response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([_l.sent()])), {
            name: errorCode,
            $metadata: deserializeMetadata(output)
          }]);
          return [3 /*break*/, 17];
        case 4:
          _d = [{}];
          return [4 /*yield*/, deserializeAws_json1_1InternalErrorExceptionResponse(parsedOutput, context)];
        case 5:
          response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([_l.sent()])), {
            name: errorCode,
            $metadata: deserializeMetadata(output)
          }]);
          return [3 /*break*/, 17];
        case 6:
          _e = [{}];
          return [4 /*yield*/, deserializeAws_json1_1InvalidParameterExceptionResponse(parsedOutput, context)];
        case 7:
          response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([_l.sent()])), {
            name: errorCode,
            $metadata: deserializeMetadata(output)
          }]);
          return [3 /*break*/, 17];
        case 8:
          _f = [{}];
          return [4 /*yield*/, deserializeAws_json1_1NotAuthorizedExceptionResponse(parsedOutput, context)];
        case 9:
          response = __assign.apply(void 0, [__assign.apply(void 0, _f.concat([_l.sent()])), {
            name: errorCode,
            $metadata: deserializeMetadata(output)
          }]);
          return [3 /*break*/, 17];
        case 10:
          _g = [{}];
          return [4 /*yield*/, deserializeAws_json1_1ResourceConflictExceptionResponse(parsedOutput, context)];
        case 11:
          response = __assign.apply(void 0, [__assign.apply(void 0, _g.concat([_l.sent()])), {
            name: errorCode,
            $metadata: deserializeMetadata(output)
          }]);
          return [3 /*break*/, 17];
        case 12:
          _h = [{}];
          return [4 /*yield*/, deserializeAws_json1_1ResourceNotFoundExceptionResponse(parsedOutput, context)];
        case 13:
          response = __assign.apply(void 0, [__assign.apply(void 0, _h.concat([_l.sent()])), {
            name: errorCode,
            $metadata: deserializeMetadata(output)
          }]);
          return [3 /*break*/, 17];
        case 14:
          _j = [{}];
          return [4 /*yield*/, deserializeAws_json1_1TooManyRequestsExceptionResponse(parsedOutput, context)];
        case 15:
          response = __assign.apply(void 0, [__assign.apply(void 0, _j.concat([_l.sent()])), {
            name: errorCode,
            $metadata: deserializeMetadata(output)
          }]);
          return [3 /*break*/, 17];
        case 16:
          parsedBody = parsedOutput.body;
          errorCode = parsedBody.code || parsedBody.Code || errorCode;
          response = __assign(__assign({}, parsedBody), {
            name: "" + errorCode,
            message: parsedBody.message || parsedBody.Message || errorCode,
            $fault: "client",
            $metadata: deserializeMetadata(output)
          });
          _l.label = 17;
        case 17:
          message = response.message || response.Message || errorCode;
          response.message = message;
          delete response.Message;
          return [2 /*return*/, Promise.reject(Object.assign(new Error(message), response))];
      }
    });
  });
};
export var deserializeAws_json1_1UntagResourceCommand = function (output, context) {
  return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          if (output.statusCode >= 300) {
            return [2 /*return*/, deserializeAws_json1_1UntagResourceCommandError(output, context)];
          }
          return [4 /*yield*/, parseBody(output.body, context)];
        case 1:
          data = _a.sent();
          contents = {};
          contents = deserializeAws_json1_1UntagResourceResponse(data, context);
          response = __assign({
            $metadata: deserializeMetadata(output)
          }, contents);
          return [2 /*return*/, Promise.resolve(response)];
      }
    });
  });
};
var deserializeAws_json1_1UntagResourceCommandError = function (output, context) {
  return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, _f, _g, parsedBody, message;
    var _h;
    return __generator(this, function (_j) {
      switch (_j.label) {
        case 0:
          _a = [__assign({}, output)];
          _h = {};
          return [4 /*yield*/, parseBody(output.body, context)];
        case 1:
          parsedOutput = __assign.apply(void 0, _a.concat([(_h.body = _j.sent(), _h)]));
          errorCode = "UnknownError";
          errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
          _b = errorCode;
          switch (_b) {
            case "InternalErrorException":
              return [3 /*break*/, 2];
            case "com.amazonaws.cognitoidentity#InternalErrorException":
              return [3 /*break*/, 2];
            case "InvalidParameterException":
              return [3 /*break*/, 4];
            case "com.amazonaws.cognitoidentity#InvalidParameterException":
              return [3 /*break*/, 4];
            case "NotAuthorizedException":
              return [3 /*break*/, 6];
            case "com.amazonaws.cognitoidentity#NotAuthorizedException":
              return [3 /*break*/, 6];
            case "ResourceNotFoundException":
              return [3 /*break*/, 8];
            case "com.amazonaws.cognitoidentity#ResourceNotFoundException":
              return [3 /*break*/, 8];
            case "TooManyRequestsException":
              return [3 /*break*/, 10];
            case "com.amazonaws.cognitoidentity#TooManyRequestsException":
              return [3 /*break*/, 10];
          }
          return [3 /*break*/, 12];
        case 2:
          _c = [{}];
          return [4 /*yield*/, deserializeAws_json1_1InternalErrorExceptionResponse(parsedOutput, context)];
        case 3:
          response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([_j.sent()])), {
            name: errorCode,
            $metadata: deserializeMetadata(output)
          }]);
          return [3 /*break*/, 13];
        case 4:
          _d = [{}];
          return [4 /*yield*/, deserializeAws_json1_1InvalidParameterExceptionResponse(parsedOutput, context)];
        case 5:
          response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([_j.sent()])), {
            name: errorCode,
            $metadata: deserializeMetadata(output)
          }]);
          return [3 /*break*/, 13];
        case 6:
          _e = [{}];
          return [4 /*yield*/, deserializeAws_json1_1NotAuthorizedExceptionResponse(parsedOutput, context)];
        case 7:
          response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([_j.sent()])), {
            name: errorCode,
            $metadata: deserializeMetadata(output)
          }]);
          return [3 /*break*/, 13];
        case 8:
          _f = [{}];
          return [4 /*yield*/, deserializeAws_json1_1ResourceNotFoundExceptionResponse(parsedOutput, context)];
        case 9:
          response = __assign.apply(void 0, [__assign.apply(void 0, _f.concat([_j.sent()])), {
            name: errorCode,
            $metadata: deserializeMetadata(output)
          }]);
          return [3 /*break*/, 13];
        case 10:
          _g = [{}];
          return [4 /*yield*/, deserializeAws_json1_1TooManyRequestsExceptionResponse(parsedOutput, context)];
        case 11:
          response = __assign.apply(void 0, [__assign.apply(void 0, _g.concat([_j.sent()])), {
            name: errorCode,
            $metadata: deserializeMetadata(output)
          }]);
          return [3 /*break*/, 13];
        case 12:
          parsedBody = parsedOutput.body;
          errorCode = parsedBody.code || parsedBody.Code || errorCode;
          response = __assign(__assign({}, parsedBody), {
            name: "" + errorCode,
            message: parsedBody.message || parsedBody.Message || errorCode,
            $fault: "client",
            $metadata: deserializeMetadata(output)
          });
          _j.label = 13;
        case 13:
          message = response.message || response.Message || errorCode;
          response.message = message;
          delete response.Message;
          return [2 /*return*/, Promise.reject(Object.assign(new Error(message), response))];
      }
    });
  });
};
export var deserializeAws_json1_1UpdateIdentityPoolCommand = function (output, context) {
  return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          if (output.statusCode >= 300) {
            return [2 /*return*/, deserializeAws_json1_1UpdateIdentityPoolCommandError(output, context)];
          }
          return [4 /*yield*/, parseBody(output.body, context)];
        case 1:
          data = _a.sent();
          contents = {};
          contents = deserializeAws_json1_1IdentityPool(data, context);
          response = __assign({
            $metadata: deserializeMetadata(output)
          }, contents);
          return [2 /*return*/, Promise.resolve(response)];
      }
    });
  });
};
var deserializeAws_json1_1UpdateIdentityPoolCommandError = function (output, context) {
  return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, _f, _g, _h, _j, _k, parsedBody, message;
    var _l;
    return __generator(this, function (_m) {
      switch (_m.label) {
        case 0:
          _a = [__assign({}, output)];
          _l = {};
          return [4 /*yield*/, parseBody(output.body, context)];
        case 1:
          parsedOutput = __assign.apply(void 0, _a.concat([(_l.body = _m.sent(), _l)]));
          errorCode = "UnknownError";
          errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
          _b = errorCode;
          switch (_b) {
            case "ConcurrentModificationException":
              return [3 /*break*/, 2];
            case "com.amazonaws.cognitoidentity#ConcurrentModificationException":
              return [3 /*break*/, 2];
            case "InternalErrorException":
              return [3 /*break*/, 4];
            case "com.amazonaws.cognitoidentity#InternalErrorException":
              return [3 /*break*/, 4];
            case "InvalidParameterException":
              return [3 /*break*/, 6];
            case "com.amazonaws.cognitoidentity#InvalidParameterException":
              return [3 /*break*/, 6];
            case "LimitExceededException":
              return [3 /*break*/, 8];
            case "com.amazonaws.cognitoidentity#LimitExceededException":
              return [3 /*break*/, 8];
            case "NotAuthorizedException":
              return [3 /*break*/, 10];
            case "com.amazonaws.cognitoidentity#NotAuthorizedException":
              return [3 /*break*/, 10];
            case "ResourceConflictException":
              return [3 /*break*/, 12];
            case "com.amazonaws.cognitoidentity#ResourceConflictException":
              return [3 /*break*/, 12];
            case "ResourceNotFoundException":
              return [3 /*break*/, 14];
            case "com.amazonaws.cognitoidentity#ResourceNotFoundException":
              return [3 /*break*/, 14];
            case "TooManyRequestsException":
              return [3 /*break*/, 16];
            case "com.amazonaws.cognitoidentity#TooManyRequestsException":
              return [3 /*break*/, 16];
          }
          return [3 /*break*/, 18];
        case 2:
          _c = [{}];
          return [4 /*yield*/, deserializeAws_json1_1ConcurrentModificationExceptionResponse(parsedOutput, context)];
        case 3:
          response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([_m.sent()])), {
            name: errorCode,
            $metadata: deserializeMetadata(output)
          }]);
          return [3 /*break*/, 19];
        case 4:
          _d = [{}];
          return [4 /*yield*/, deserializeAws_json1_1InternalErrorExceptionResponse(parsedOutput, context)];
        case 5:
          response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([_m.sent()])), {
            name: errorCode,
            $metadata: deserializeMetadata(output)
          }]);
          return [3 /*break*/, 19];
        case 6:
          _e = [{}];
          return [4 /*yield*/, deserializeAws_json1_1InvalidParameterExceptionResponse(parsedOutput, context)];
        case 7:
          response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([_m.sent()])), {
            name: errorCode,
            $metadata: deserializeMetadata(output)
          }]);
          return [3 /*break*/, 19];
        case 8:
          _f = [{}];
          return [4 /*yield*/, deserializeAws_json1_1LimitExceededExceptionResponse(parsedOutput, context)];
        case 9:
          response = __assign.apply(void 0, [__assign.apply(void 0, _f.concat([_m.sent()])), {
            name: errorCode,
            $metadata: deserializeMetadata(output)
          }]);
          return [3 /*break*/, 19];
        case 10:
          _g = [{}];
          return [4 /*yield*/, deserializeAws_json1_1NotAuthorizedExceptionResponse(parsedOutput, context)];
        case 11:
          response = __assign.apply(void 0, [__assign.apply(void 0, _g.concat([_m.sent()])), {
            name: errorCode,
            $metadata: deserializeMetadata(output)
          }]);
          return [3 /*break*/, 19];
        case 12:
          _h = [{}];
          return [4 /*yield*/, deserializeAws_json1_1ResourceConflictExceptionResponse(parsedOutput, context)];
        case 13:
          response = __assign.apply(void 0, [__assign.apply(void 0, _h.concat([_m.sent()])), {
            name: errorCode,
            $metadata: deserializeMetadata(output)
          }]);
          return [3 /*break*/, 19];
        case 14:
          _j = [{}];
          return [4 /*yield*/, deserializeAws_json1_1ResourceNotFoundExceptionResponse(parsedOutput, context)];
        case 15:
          response = __assign.apply(void 0, [__assign.apply(void 0, _j.concat([_m.sent()])), {
            name: errorCode,
            $metadata: deserializeMetadata(output)
          }]);
          return [3 /*break*/, 19];
        case 16:
          _k = [{}];
          return [4 /*yield*/, deserializeAws_json1_1TooManyRequestsExceptionResponse(parsedOutput, context)];
        case 17:
          response = __assign.apply(void 0, [__assign.apply(void 0, _k.concat([_m.sent()])), {
            name: errorCode,
            $metadata: deserializeMetadata(output)
          }]);
          return [3 /*break*/, 19];
        case 18:
          parsedBody = parsedOutput.body;
          errorCode = parsedBody.code || parsedBody.Code || errorCode;
          response = __assign(__assign({}, parsedBody), {
            name: "" + errorCode,
            message: parsedBody.message || parsedBody.Message || errorCode,
            $fault: "client",
            $metadata: deserializeMetadata(output)
          });
          _m.label = 19;
        case 19:
          message = response.message || response.Message || errorCode;
          response.message = message;
          delete response.Message;
          return [2 /*return*/, Promise.reject(Object.assign(new Error(message), response))];
      }
    });
  });
};
var deserializeAws_json1_1ConcurrentModificationExceptionResponse = function (parsedOutput, context) {
  return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, contents;
    return __generator(this, function (_a) {
      body = parsedOutput.body;
      deserialized = deserializeAws_json1_1ConcurrentModificationException(body, context);
      contents = __assign({
        name: "ConcurrentModificationException",
        $fault: "client",
        $metadata: deserializeMetadata(parsedOutput)
      }, deserialized);
      return [2 /*return*/, contents];
    });
  });
};
var deserializeAws_json1_1DeveloperUserAlreadyRegisteredExceptionResponse = function (parsedOutput, context) {
  return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, contents;
    return __generator(this, function (_a) {
      body = parsedOutput.body;
      deserialized = deserializeAws_json1_1DeveloperUserAlreadyRegisteredException(body, context);
      contents = __assign({
        name: "DeveloperUserAlreadyRegisteredException",
        $fault: "client",
        $metadata: deserializeMetadata(parsedOutput)
      }, deserialized);
      return [2 /*return*/, contents];
    });
  });
};
var deserializeAws_json1_1ExternalServiceExceptionResponse = function (parsedOutput, context) {
  return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, contents;
    return __generator(this, function (_a) {
      body = parsedOutput.body;
      deserialized = deserializeAws_json1_1ExternalServiceException(body, context);
      contents = __assign({
        name: "ExternalServiceException",
        $fault: "client",
        $metadata: deserializeMetadata(parsedOutput)
      }, deserialized);
      return [2 /*return*/, contents];
    });
  });
};
var deserializeAws_json1_1InternalErrorExceptionResponse = function (parsedOutput, context) {
  return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, contents;
    return __generator(this, function (_a) {
      body = parsedOutput.body;
      deserialized = deserializeAws_json1_1InternalErrorException(body, context);
      contents = __assign({
        name: "InternalErrorException",
        $fault: "server",
        $metadata: deserializeMetadata(parsedOutput)
      }, deserialized);
      return [2 /*return*/, contents];
    });
  });
};
var deserializeAws_json1_1InvalidIdentityPoolConfigurationExceptionResponse = function (parsedOutput, context) {
  return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, contents;
    return __generator(this, function (_a) {
      body = parsedOutput.body;
      deserialized = deserializeAws_json1_1InvalidIdentityPoolConfigurationException(body, context);
      contents = __assign({
        name: "InvalidIdentityPoolConfigurationException",
        $fault: "client",
        $metadata: deserializeMetadata(parsedOutput)
      }, deserialized);
      return [2 /*return*/, contents];
    });
  });
};
var deserializeAws_json1_1InvalidParameterExceptionResponse = function (parsedOutput, context) {
  return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, contents;
    return __generator(this, function (_a) {
      body = parsedOutput.body;
      deserialized = deserializeAws_json1_1InvalidParameterException(body, context);
      contents = __assign({
        name: "InvalidParameterException",
        $fault: "client",
        $metadata: deserializeMetadata(parsedOutput)
      }, deserialized);
      return [2 /*return*/, contents];
    });
  });
};
var deserializeAws_json1_1LimitExceededExceptionResponse = function (parsedOutput, context) {
  return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, contents;
    return __generator(this, function (_a) {
      body = parsedOutput.body;
      deserialized = deserializeAws_json1_1LimitExceededException(body, context);
      contents = __assign({
        name: "LimitExceededException",
        $fault: "client",
        $metadata: deserializeMetadata(parsedOutput)
      }, deserialized);
      return [2 /*return*/, contents];
    });
  });
};
var deserializeAws_json1_1NotAuthorizedExceptionResponse = function (parsedOutput, context) {
  return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, contents;
    return __generator(this, function (_a) {
      body = parsedOutput.body;
      deserialized = deserializeAws_json1_1NotAuthorizedException(body, context);
      contents = __assign({
        name: "NotAuthorizedException",
        $fault: "client",
        $metadata: deserializeMetadata(parsedOutput)
      }, deserialized);
      return [2 /*return*/, contents];
    });
  });
};
var deserializeAws_json1_1ResourceConflictExceptionResponse = function (parsedOutput, context) {
  return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, contents;
    return __generator(this, function (_a) {
      body = parsedOutput.body;
      deserialized = deserializeAws_json1_1ResourceConflictException(body, context);
      contents = __assign({
        name: "ResourceConflictException",
        $fault: "client",
        $metadata: deserializeMetadata(parsedOutput)
      }, deserialized);
      return [2 /*return*/, contents];
    });
  });
};
var deserializeAws_json1_1ResourceNotFoundExceptionResponse = function (parsedOutput, context) {
  return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, contents;
    return __generator(this, function (_a) {
      body = parsedOutput.body;
      deserialized = deserializeAws_json1_1ResourceNotFoundException(body, context);
      contents = __assign({
        name: "ResourceNotFoundException",
        $fault: "client",
        $metadata: deserializeMetadata(parsedOutput)
      }, deserialized);
      return [2 /*return*/, contents];
    });
  });
};
var deserializeAws_json1_1TooManyRequestsExceptionResponse = function (parsedOutput, context) {
  return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, contents;
    return __generator(this, function (_a) {
      body = parsedOutput.body;
      deserialized = deserializeAws_json1_1TooManyRequestsException(body, context);
      contents = __assign({
        name: "TooManyRequestsException",
        $fault: "client",
        $metadata: deserializeMetadata(parsedOutput)
      }, deserialized);
      return [2 /*return*/, contents];
    });
  });
};
var serializeAws_json1_1CognitoIdentityProvider = function (input, context) {
  return __assign(__assign(__assign({}, input.ClientId !== undefined && input.ClientId !== null && {
    ClientId: input.ClientId
  }), input.ProviderName !== undefined && input.ProviderName !== null && {
    ProviderName: input.ProviderName
  }), input.ServerSideTokenCheck !== undefined && input.ServerSideTokenCheck !== null && {
    ServerSideTokenCheck: input.ServerSideTokenCheck
  });
};
var serializeAws_json1_1CognitoIdentityProviderList = function (input, context) {
  return input.filter(function (e) {
    return e != null;
  }).map(function (entry) {
    if (entry === null) {
      return null;
    }
    return serializeAws_json1_1CognitoIdentityProvider(entry, context);
  });
};
var serializeAws_json1_1CreateIdentityPoolInput = function (input, context) {
  return __assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign({}, input.AllowClassicFlow !== undefined && input.AllowClassicFlow !== null && {
    AllowClassicFlow: input.AllowClassicFlow
  }), input.AllowUnauthenticatedIdentities !== undefined && input.AllowUnauthenticatedIdentities !== null && {
    AllowUnauthenticatedIdentities: input.AllowUnauthenticatedIdentities
  }), input.CognitoIdentityProviders !== undefined && input.CognitoIdentityProviders !== null && {
    CognitoIdentityProviders: serializeAws_json1_1CognitoIdentityProviderList(input.CognitoIdentityProviders, context)
  }), input.DeveloperProviderName !== undefined && input.DeveloperProviderName !== null && {
    DeveloperProviderName: input.DeveloperProviderName
  }), input.IdentityPoolName !== undefined && input.IdentityPoolName !== null && {
    IdentityPoolName: input.IdentityPoolName
  }), input.IdentityPoolTags !== undefined && input.IdentityPoolTags !== null && {
    IdentityPoolTags: serializeAws_json1_1IdentityPoolTagsType(input.IdentityPoolTags, context)
  }), input.OpenIdConnectProviderARNs !== undefined && input.OpenIdConnectProviderARNs !== null && {
    OpenIdConnectProviderARNs: serializeAws_json1_1OIDCProviderList(input.OpenIdConnectProviderARNs, context)
  }), input.SamlProviderARNs !== undefined && input.SamlProviderARNs !== null && {
    SamlProviderARNs: serializeAws_json1_1SAMLProviderList(input.SamlProviderARNs, context)
  }), input.SupportedLoginProviders !== undefined && input.SupportedLoginProviders !== null && {
    SupportedLoginProviders: serializeAws_json1_1IdentityProviders(input.SupportedLoginProviders, context)
  });
};
var serializeAws_json1_1DeleteIdentitiesInput = function (input, context) {
  return __assign({}, input.IdentityIdsToDelete !== undefined && input.IdentityIdsToDelete !== null && {
    IdentityIdsToDelete: serializeAws_json1_1IdentityIdList(input.IdentityIdsToDelete, context)
  });
};
var serializeAws_json1_1DeleteIdentityPoolInput = function (input, context) {
  return __assign({}, input.IdentityPoolId !== undefined && input.IdentityPoolId !== null && {
    IdentityPoolId: input.IdentityPoolId
  });
};
var serializeAws_json1_1DescribeIdentityInput = function (input, context) {
  return __assign({}, input.IdentityId !== undefined && input.IdentityId !== null && {
    IdentityId: input.IdentityId
  });
};
var serializeAws_json1_1DescribeIdentityPoolInput = function (input, context) {
  return __assign({}, input.IdentityPoolId !== undefined && input.IdentityPoolId !== null && {
    IdentityPoolId: input.IdentityPoolId
  });
};
var serializeAws_json1_1GetCredentialsForIdentityInput = function (input, context) {
  return __assign(__assign(__assign({}, input.CustomRoleArn !== undefined && input.CustomRoleArn !== null && {
    CustomRoleArn: input.CustomRoleArn
  }), input.IdentityId !== undefined && input.IdentityId !== null && {
    IdentityId: input.IdentityId
  }), input.Logins !== undefined && input.Logins !== null && {
    Logins: serializeAws_json1_1LoginsMap(input.Logins, context)
  });
};
var serializeAws_json1_1GetIdentityPoolRolesInput = function (input, context) {
  return __assign({}, input.IdentityPoolId !== undefined && input.IdentityPoolId !== null && {
    IdentityPoolId: input.IdentityPoolId
  });
};
var serializeAws_json1_1GetIdInput = function (input, context) {
  return __assign(__assign(__assign({}, input.AccountId !== undefined && input.AccountId !== null && {
    AccountId: input.AccountId
  }), input.IdentityPoolId !== undefined && input.IdentityPoolId !== null && {
    IdentityPoolId: input.IdentityPoolId
  }), input.Logins !== undefined && input.Logins !== null && {
    Logins: serializeAws_json1_1LoginsMap(input.Logins, context)
  });
};
var serializeAws_json1_1GetOpenIdTokenForDeveloperIdentityInput = function (input, context) {
  return __assign(__assign(__assign(__assign({}, input.IdentityId !== undefined && input.IdentityId !== null && {
    IdentityId: input.IdentityId
  }), input.IdentityPoolId !== undefined && input.IdentityPoolId !== null && {
    IdentityPoolId: input.IdentityPoolId
  }), input.Logins !== undefined && input.Logins !== null && {
    Logins: serializeAws_json1_1LoginsMap(input.Logins, context)
  }), input.TokenDuration !== undefined && input.TokenDuration !== null && {
    TokenDuration: input.TokenDuration
  });
};
var serializeAws_json1_1GetOpenIdTokenInput = function (input, context) {
  return __assign(__assign({}, input.IdentityId !== undefined && input.IdentityId !== null && {
    IdentityId: input.IdentityId
  }), input.Logins !== undefined && input.Logins !== null && {
    Logins: serializeAws_json1_1LoginsMap(input.Logins, context)
  });
};
var serializeAws_json1_1IdentityIdList = function (input, context) {
  return input.filter(function (e) {
    return e != null;
  }).map(function (entry) {
    if (entry === null) {
      return null;
    }
    return entry;
  });
};
var serializeAws_json1_1IdentityPool = function (input, context) {
  return __assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign({}, input.AllowClassicFlow !== undefined && input.AllowClassicFlow !== null && {
    AllowClassicFlow: input.AllowClassicFlow
  }), input.AllowUnauthenticatedIdentities !== undefined && input.AllowUnauthenticatedIdentities !== null && {
    AllowUnauthenticatedIdentities: input.AllowUnauthenticatedIdentities
  }), input.CognitoIdentityProviders !== undefined && input.CognitoIdentityProviders !== null && {
    CognitoIdentityProviders: serializeAws_json1_1CognitoIdentityProviderList(input.CognitoIdentityProviders, context)
  }), input.DeveloperProviderName !== undefined && input.DeveloperProviderName !== null && {
    DeveloperProviderName: input.DeveloperProviderName
  }), input.IdentityPoolId !== undefined && input.IdentityPoolId !== null && {
    IdentityPoolId: input.IdentityPoolId
  }), input.IdentityPoolName !== undefined && input.IdentityPoolName !== null && {
    IdentityPoolName: input.IdentityPoolName
  }), input.IdentityPoolTags !== undefined && input.IdentityPoolTags !== null && {
    IdentityPoolTags: serializeAws_json1_1IdentityPoolTagsType(input.IdentityPoolTags, context)
  }), input.OpenIdConnectProviderARNs !== undefined && input.OpenIdConnectProviderARNs !== null && {
    OpenIdConnectProviderARNs: serializeAws_json1_1OIDCProviderList(input.OpenIdConnectProviderARNs, context)
  }), input.SamlProviderARNs !== undefined && input.SamlProviderARNs !== null && {
    SamlProviderARNs: serializeAws_json1_1SAMLProviderList(input.SamlProviderARNs, context)
  }), input.SupportedLoginProviders !== undefined && input.SupportedLoginProviders !== null && {
    SupportedLoginProviders: serializeAws_json1_1IdentityProviders(input.SupportedLoginProviders, context)
  });
};
var serializeAws_json1_1IdentityPoolTagsListType = function (input, context) {
  return input.filter(function (e) {
    return e != null;
  }).map(function (entry) {
    if (entry === null) {
      return null;
    }
    return entry;
  });
};
var serializeAws_json1_1IdentityPoolTagsType = function (input, context) {
  return Object.entries(input).reduce(function (acc, _a) {
    var _b;
    var _c = __read(_a, 2),
      key = _c[0],
      value = _c[1];
    if (value === null) {
      return acc;
    }
    return __assign(__assign({}, acc), (_b = {}, _b[key] = value, _b));
  }, {});
};
var serializeAws_json1_1IdentityProviders = function (input, context) {
  return Object.entries(input).reduce(function (acc, _a) {
    var _b;
    var _c = __read(_a, 2),
      key = _c[0],
      value = _c[1];
    if (value === null) {
      return acc;
    }
    return __assign(__assign({}, acc), (_b = {}, _b[key] = value, _b));
  }, {});
};
var serializeAws_json1_1ListIdentitiesInput = function (input, context) {
  return __assign(__assign(__assign(__assign({}, input.HideDisabled !== undefined && input.HideDisabled !== null && {
    HideDisabled: input.HideDisabled
  }), input.IdentityPoolId !== undefined && input.IdentityPoolId !== null && {
    IdentityPoolId: input.IdentityPoolId
  }), input.MaxResults !== undefined && input.MaxResults !== null && {
    MaxResults: input.MaxResults
  }), input.NextToken !== undefined && input.NextToken !== null && {
    NextToken: input.NextToken
  });
};
var serializeAws_json1_1ListIdentityPoolsInput = function (input, context) {
  return __assign(__assign({}, input.MaxResults !== undefined && input.MaxResults !== null && {
    MaxResults: input.MaxResults
  }), input.NextToken !== undefined && input.NextToken !== null && {
    NextToken: input.NextToken
  });
};
var serializeAws_json1_1ListTagsForResourceInput = function (input, context) {
  return __assign({}, input.ResourceArn !== undefined && input.ResourceArn !== null && {
    ResourceArn: input.ResourceArn
  });
};
var serializeAws_json1_1LoginsList = function (input, context) {
  return input.filter(function (e) {
    return e != null;
  }).map(function (entry) {
    if (entry === null) {
      return null;
    }
    return entry;
  });
};
var serializeAws_json1_1LoginsMap = function (input, context) {
  return Object.entries(input).reduce(function (acc, _a) {
    var _b;
    var _c = __read(_a, 2),
      key = _c[0],
      value = _c[1];
    if (value === null) {
      return acc;
    }
    return __assign(__assign({}, acc), (_b = {}, _b[key] = value, _b));
  }, {});
};
var serializeAws_json1_1LookupDeveloperIdentityInput = function (input, context) {
  return __assign(__assign(__assign(__assign(__assign({}, input.DeveloperUserIdentifier !== undefined && input.DeveloperUserIdentifier !== null && {
    DeveloperUserIdentifier: input.DeveloperUserIdentifier
  }), input.IdentityId !== undefined && input.IdentityId !== null && {
    IdentityId: input.IdentityId
  }), input.IdentityPoolId !== undefined && input.IdentityPoolId !== null && {
    IdentityPoolId: input.IdentityPoolId
  }), input.MaxResults !== undefined && input.MaxResults !== null && {
    MaxResults: input.MaxResults
  }), input.NextToken !== undefined && input.NextToken !== null && {
    NextToken: input.NextToken
  });
};
var serializeAws_json1_1MappingRule = function (input, context) {
  return __assign(__assign(__assign(__assign({}, input.Claim !== undefined && input.Claim !== null && {
    Claim: input.Claim
  }), input.MatchType !== undefined && input.MatchType !== null && {
    MatchType: input.MatchType
  }), input.RoleARN !== undefined && input.RoleARN !== null && {
    RoleARN: input.RoleARN
  }), input.Value !== undefined && input.Value !== null && {
    Value: input.Value
  });
};
var serializeAws_json1_1MappingRulesList = function (input, context) {
  return input.filter(function (e) {
    return e != null;
  }).map(function (entry) {
    if (entry === null) {
      return null;
    }
    return serializeAws_json1_1MappingRule(entry, context);
  });
};
var serializeAws_json1_1MergeDeveloperIdentitiesInput = function (input, context) {
  return __assign(__assign(__assign(__assign({}, input.DestinationUserIdentifier !== undefined && input.DestinationUserIdentifier !== null && {
    DestinationUserIdentifier: input.DestinationUserIdentifier
  }), input.DeveloperProviderName !== undefined && input.DeveloperProviderName !== null && {
    DeveloperProviderName: input.DeveloperProviderName
  }), input.IdentityPoolId !== undefined && input.IdentityPoolId !== null && {
    IdentityPoolId: input.IdentityPoolId
  }), input.SourceUserIdentifier !== undefined && input.SourceUserIdentifier !== null && {
    SourceUserIdentifier: input.SourceUserIdentifier
  });
};
var serializeAws_json1_1OIDCProviderList = function (input, context) {
  return input.filter(function (e) {
    return e != null;
  }).map(function (entry) {
    if (entry === null) {
      return null;
    }
    return entry;
  });
};
var serializeAws_json1_1RoleMapping = function (input, context) {
  return __assign(__assign(__assign({}, input.AmbiguousRoleResolution !== undefined && input.AmbiguousRoleResolution !== null && {
    AmbiguousRoleResolution: input.AmbiguousRoleResolution
  }), input.RulesConfiguration !== undefined && input.RulesConfiguration !== null && {
    RulesConfiguration: serializeAws_json1_1RulesConfigurationType(input.RulesConfiguration, context)
  }), input.Type !== undefined && input.Type !== null && {
    Type: input.Type
  });
};
var serializeAws_json1_1RoleMappingMap = function (input, context) {
  return Object.entries(input).reduce(function (acc, _a) {
    var _b;
    var _c = __read(_a, 2),
      key = _c[0],
      value = _c[1];
    if (value === null) {
      return acc;
    }
    return __assign(__assign({}, acc), (_b = {}, _b[key] = serializeAws_json1_1RoleMapping(value, context), _b));
  }, {});
};
var serializeAws_json1_1RolesMap = function (input, context) {
  return Object.entries(input).reduce(function (acc, _a) {
    var _b;
    var _c = __read(_a, 2),
      key = _c[0],
      value = _c[1];
    if (value === null) {
      return acc;
    }
    return __assign(__assign({}, acc), (_b = {}, _b[key] = value, _b));
  }, {});
};
var serializeAws_json1_1RulesConfigurationType = function (input, context) {
  return __assign({}, input.Rules !== undefined && input.Rules !== null && {
    Rules: serializeAws_json1_1MappingRulesList(input.Rules, context)
  });
};
var serializeAws_json1_1SAMLProviderList = function (input, context) {
  return input.filter(function (e) {
    return e != null;
  }).map(function (entry) {
    if (entry === null) {
      return null;
    }
    return entry;
  });
};
var serializeAws_json1_1SetIdentityPoolRolesInput = function (input, context) {
  return __assign(__assign(__assign({}, input.IdentityPoolId !== undefined && input.IdentityPoolId !== null && {
    IdentityPoolId: input.IdentityPoolId
  }), input.RoleMappings !== undefined && input.RoleMappings !== null && {
    RoleMappings: serializeAws_json1_1RoleMappingMap(input.RoleMappings, context)
  }), input.Roles !== undefined && input.Roles !== null && {
    Roles: serializeAws_json1_1RolesMap(input.Roles, context)
  });
};
var serializeAws_json1_1TagResourceInput = function (input, context) {
  return __assign(__assign({}, input.ResourceArn !== undefined && input.ResourceArn !== null && {
    ResourceArn: input.ResourceArn
  }), input.Tags !== undefined && input.Tags !== null && {
    Tags: serializeAws_json1_1IdentityPoolTagsType(input.Tags, context)
  });
};
var serializeAws_json1_1UnlinkDeveloperIdentityInput = function (input, context) {
  return __assign(__assign(__assign(__assign({}, input.DeveloperProviderName !== undefined && input.DeveloperProviderName !== null && {
    DeveloperProviderName: input.DeveloperProviderName
  }), input.DeveloperUserIdentifier !== undefined && input.DeveloperUserIdentifier !== null && {
    DeveloperUserIdentifier: input.DeveloperUserIdentifier
  }), input.IdentityId !== undefined && input.IdentityId !== null && {
    IdentityId: input.IdentityId
  }), input.IdentityPoolId !== undefined && input.IdentityPoolId !== null && {
    IdentityPoolId: input.IdentityPoolId
  });
};
var serializeAws_json1_1UnlinkIdentityInput = function (input, context) {
  return __assign(__assign(__assign({}, input.IdentityId !== undefined && input.IdentityId !== null && {
    IdentityId: input.IdentityId
  }), input.Logins !== undefined && input.Logins !== null && {
    Logins: serializeAws_json1_1LoginsMap(input.Logins, context)
  }), input.LoginsToRemove !== undefined && input.LoginsToRemove !== null && {
    LoginsToRemove: serializeAws_json1_1LoginsList(input.LoginsToRemove, context)
  });
};
var serializeAws_json1_1UntagResourceInput = function (input, context) {
  return __assign(__assign({}, input.ResourceArn !== undefined && input.ResourceArn !== null && {
    ResourceArn: input.ResourceArn
  }), input.TagKeys !== undefined && input.TagKeys !== null && {
    TagKeys: serializeAws_json1_1IdentityPoolTagsListType(input.TagKeys, context)
  });
};
var deserializeAws_json1_1CognitoIdentityProvider = function (output, context) {
  return {
    ClientId: output.ClientId !== undefined && output.ClientId !== null ? output.ClientId : undefined,
    ProviderName: output.ProviderName !== undefined && output.ProviderName !== null ? output.ProviderName : undefined,
    ServerSideTokenCheck: output.ServerSideTokenCheck !== undefined && output.ServerSideTokenCheck !== null ? output.ServerSideTokenCheck : undefined
  };
};
var deserializeAws_json1_1CognitoIdentityProviderList = function (output, context) {
  return (output || []).filter(function (e) {
    return e != null;
  }).map(function (entry) {
    if (entry === null) {
      return null;
    }
    return deserializeAws_json1_1CognitoIdentityProvider(entry, context);
  });
};
var deserializeAws_json1_1ConcurrentModificationException = function (output, context) {
  return {
    message: output.message !== undefined && output.message !== null ? output.message : undefined
  };
};
var deserializeAws_json1_1Credentials = function (output, context) {
  return {
    AccessKeyId: output.AccessKeyId !== undefined && output.AccessKeyId !== null ? output.AccessKeyId : undefined,
    Expiration: output.Expiration !== undefined && output.Expiration !== null ? new Date(Math.round(output.Expiration * 1000)) : undefined,
    SecretKey: output.SecretKey !== undefined && output.SecretKey !== null ? output.SecretKey : undefined,
    SessionToken: output.SessionToken !== undefined && output.SessionToken !== null ? output.SessionToken : undefined
  };
};
var deserializeAws_json1_1DeleteIdentitiesResponse = function (output, context) {
  return {
    UnprocessedIdentityIds: output.UnprocessedIdentityIds !== undefined && output.UnprocessedIdentityIds !== null ? deserializeAws_json1_1UnprocessedIdentityIdList(output.UnprocessedIdentityIds, context) : undefined
  };
};
var deserializeAws_json1_1DeveloperUserAlreadyRegisteredException = function (output, context) {
  return {
    message: output.message !== undefined && output.message !== null ? output.message : undefined
  };
};
var deserializeAws_json1_1DeveloperUserIdentifierList = function (output, context) {
  return (output || []).filter(function (e) {
    return e != null;
  }).map(function (entry) {
    if (entry === null) {
      return null;
    }
    return entry;
  });
};
var deserializeAws_json1_1ExternalServiceException = function (output, context) {
  return {
    message: output.message !== undefined && output.message !== null ? output.message : undefined
  };
};
var deserializeAws_json1_1GetCredentialsForIdentityResponse = function (output, context) {
  return {
    Credentials: output.Credentials !== undefined && output.Credentials !== null ? deserializeAws_json1_1Credentials(output.Credentials, context) : undefined,
    IdentityId: output.IdentityId !== undefined && output.IdentityId !== null ? output.IdentityId : undefined
  };
};
var deserializeAws_json1_1GetIdentityPoolRolesResponse = function (output, context) {
  return {
    IdentityPoolId: output.IdentityPoolId !== undefined && output.IdentityPoolId !== null ? output.IdentityPoolId : undefined,
    RoleMappings: output.RoleMappings !== undefined && output.RoleMappings !== null ? deserializeAws_json1_1RoleMappingMap(output.RoleMappings, context) : undefined,
    Roles: output.Roles !== undefined && output.Roles !== null ? deserializeAws_json1_1RolesMap(output.Roles, context) : undefined
  };
};
var deserializeAws_json1_1GetIdResponse = function (output, context) {
  return {
    IdentityId: output.IdentityId !== undefined && output.IdentityId !== null ? output.IdentityId : undefined
  };
};
var deserializeAws_json1_1GetOpenIdTokenForDeveloperIdentityResponse = function (output, context) {
  return {
    IdentityId: output.IdentityId !== undefined && output.IdentityId !== null ? output.IdentityId : undefined,
    Token: output.Token !== undefined && output.Token !== null ? output.Token : undefined
  };
};
var deserializeAws_json1_1GetOpenIdTokenResponse = function (output, context) {
  return {
    IdentityId: output.IdentityId !== undefined && output.IdentityId !== null ? output.IdentityId : undefined,
    Token: output.Token !== undefined && output.Token !== null ? output.Token : undefined
  };
};
var deserializeAws_json1_1IdentitiesList = function (output, context) {
  return (output || []).filter(function (e) {
    return e != null;
  }).map(function (entry) {
    if (entry === null) {
      return null;
    }
    return deserializeAws_json1_1IdentityDescription(entry, context);
  });
};
var deserializeAws_json1_1IdentityDescription = function (output, context) {
  return {
    CreationDate: output.CreationDate !== undefined && output.CreationDate !== null ? new Date(Math.round(output.CreationDate * 1000)) : undefined,
    IdentityId: output.IdentityId !== undefined && output.IdentityId !== null ? output.IdentityId : undefined,
    LastModifiedDate: output.LastModifiedDate !== undefined && output.LastModifiedDate !== null ? new Date(Math.round(output.LastModifiedDate * 1000)) : undefined,
    Logins: output.Logins !== undefined && output.Logins !== null ? deserializeAws_json1_1LoginsList(output.Logins, context) : undefined
  };
};
var deserializeAws_json1_1IdentityPool = function (output, context) {
  return {
    AllowClassicFlow: output.AllowClassicFlow !== undefined && output.AllowClassicFlow !== null ? output.AllowClassicFlow : undefined,
    AllowUnauthenticatedIdentities: output.AllowUnauthenticatedIdentities !== undefined && output.AllowUnauthenticatedIdentities !== null ? output.AllowUnauthenticatedIdentities : undefined,
    CognitoIdentityProviders: output.CognitoIdentityProviders !== undefined && output.CognitoIdentityProviders !== null ? deserializeAws_json1_1CognitoIdentityProviderList(output.CognitoIdentityProviders, context) : undefined,
    DeveloperProviderName: output.DeveloperProviderName !== undefined && output.DeveloperProviderName !== null ? output.DeveloperProviderName : undefined,
    IdentityPoolId: output.IdentityPoolId !== undefined && output.IdentityPoolId !== null ? output.IdentityPoolId : undefined,
    IdentityPoolName: output.IdentityPoolName !== undefined && output.IdentityPoolName !== null ? output.IdentityPoolName : undefined,
    IdentityPoolTags: output.IdentityPoolTags !== undefined && output.IdentityPoolTags !== null ? deserializeAws_json1_1IdentityPoolTagsType(output.IdentityPoolTags, context) : undefined,
    OpenIdConnectProviderARNs: output.OpenIdConnectProviderARNs !== undefined && output.OpenIdConnectProviderARNs !== null ? deserializeAws_json1_1OIDCProviderList(output.OpenIdConnectProviderARNs, context) : undefined,
    SamlProviderARNs: output.SamlProviderARNs !== undefined && output.SamlProviderARNs !== null ? deserializeAws_json1_1SAMLProviderList(output.SamlProviderARNs, context) : undefined,
    SupportedLoginProviders: output.SupportedLoginProviders !== undefined && output.SupportedLoginProviders !== null ? deserializeAws_json1_1IdentityProviders(output.SupportedLoginProviders, context) : undefined
  };
};
var deserializeAws_json1_1IdentityPoolShortDescription = function (output, context) {
  return {
    IdentityPoolId: output.IdentityPoolId !== undefined && output.IdentityPoolId !== null ? output.IdentityPoolId : undefined,
    IdentityPoolName: output.IdentityPoolName !== undefined && output.IdentityPoolName !== null ? output.IdentityPoolName : undefined
  };
};
var deserializeAws_json1_1IdentityPoolsList = function (output, context) {
  return (output || []).filter(function (e) {
    return e != null;
  }).map(function (entry) {
    if (entry === null) {
      return null;
    }
    return deserializeAws_json1_1IdentityPoolShortDescription(entry, context);
  });
};
var deserializeAws_json1_1IdentityPoolTagsType = function (output, context) {
  return Object.entries(output).reduce(function (acc, _a) {
    var _b;
    var _c = __read(_a, 2),
      key = _c[0],
      value = _c[1];
    if (value === null) {
      return acc;
    }
    return __assign(__assign({}, acc), (_b = {}, _b[key] = value, _b));
  }, {});
};
var deserializeAws_json1_1IdentityProviders = function (output, context) {
  return Object.entries(output).reduce(function (acc, _a) {
    var _b;
    var _c = __read(_a, 2),
      key = _c[0],
      value = _c[1];
    if (value === null) {
      return acc;
    }
    return __assign(__assign({}, acc), (_b = {}, _b[key] = value, _b));
  }, {});
};
var deserializeAws_json1_1InternalErrorException = function (output, context) {
  return {
    message: output.message !== undefined && output.message !== null ? output.message : undefined
  };
};
var deserializeAws_json1_1InvalidIdentityPoolConfigurationException = function (output, context) {
  return {
    message: output.message !== undefined && output.message !== null ? output.message : undefined
  };
};
var deserializeAws_json1_1InvalidParameterException = function (output, context) {
  return {
    message: output.message !== undefined && output.message !== null ? output.message : undefined
  };
};
var deserializeAws_json1_1LimitExceededException = function (output, context) {
  return {
    message: output.message !== undefined && output.message !== null ? output.message : undefined
  };
};
var deserializeAws_json1_1ListIdentitiesResponse = function (output, context) {
  return {
    Identities: output.Identities !== undefined && output.Identities !== null ? deserializeAws_json1_1IdentitiesList(output.Identities, context) : undefined,
    IdentityPoolId: output.IdentityPoolId !== undefined && output.IdentityPoolId !== null ? output.IdentityPoolId : undefined,
    NextToken: output.NextToken !== undefined && output.NextToken !== null ? output.NextToken : undefined
  };
};
var deserializeAws_json1_1ListIdentityPoolsResponse = function (output, context) {
  return {
    IdentityPools: output.IdentityPools !== undefined && output.IdentityPools !== null ? deserializeAws_json1_1IdentityPoolsList(output.IdentityPools, context) : undefined,
    NextToken: output.NextToken !== undefined && output.NextToken !== null ? output.NextToken : undefined
  };
};
var deserializeAws_json1_1ListTagsForResourceResponse = function (output, context) {
  return {
    Tags: output.Tags !== undefined && output.Tags !== null ? deserializeAws_json1_1IdentityPoolTagsType(output.Tags, context) : undefined
  };
};
var deserializeAws_json1_1LoginsList = function (output, context) {
  return (output || []).filter(function (e) {
    return e != null;
  }).map(function (entry) {
    if (entry === null) {
      return null;
    }
    return entry;
  });
};
var deserializeAws_json1_1LookupDeveloperIdentityResponse = function (output, context) {
  return {
    DeveloperUserIdentifierList: output.DeveloperUserIdentifierList !== undefined && output.DeveloperUserIdentifierList !== null ? deserializeAws_json1_1DeveloperUserIdentifierList(output.DeveloperUserIdentifierList, context) : undefined,
    IdentityId: output.IdentityId !== undefined && output.IdentityId !== null ? output.IdentityId : undefined,
    NextToken: output.NextToken !== undefined && output.NextToken !== null ? output.NextToken : undefined
  };
};
var deserializeAws_json1_1MappingRule = function (output, context) {
  return {
    Claim: output.Claim !== undefined && output.Claim !== null ? output.Claim : undefined,
    MatchType: output.MatchType !== undefined && output.MatchType !== null ? output.MatchType : undefined,
    RoleARN: output.RoleARN !== undefined && output.RoleARN !== null ? output.RoleARN : undefined,
    Value: output.Value !== undefined && output.Value !== null ? output.Value : undefined
  };
};
var deserializeAws_json1_1MappingRulesList = function (output, context) {
  return (output || []).filter(function (e) {
    return e != null;
  }).map(function (entry) {
    if (entry === null) {
      return null;
    }
    return deserializeAws_json1_1MappingRule(entry, context);
  });
};
var deserializeAws_json1_1MergeDeveloperIdentitiesResponse = function (output, context) {
  return {
    IdentityId: output.IdentityId !== undefined && output.IdentityId !== null ? output.IdentityId : undefined
  };
};
var deserializeAws_json1_1NotAuthorizedException = function (output, context) {
  return {
    message: output.message !== undefined && output.message !== null ? output.message : undefined
  };
};
var deserializeAws_json1_1OIDCProviderList = function (output, context) {
  return (output || []).filter(function (e) {
    return e != null;
  }).map(function (entry) {
    if (entry === null) {
      return null;
    }
    return entry;
  });
};
var deserializeAws_json1_1ResourceConflictException = function (output, context) {
  return {
    message: output.message !== undefined && output.message !== null ? output.message : undefined
  };
};
var deserializeAws_json1_1ResourceNotFoundException = function (output, context) {
  return {
    message: output.message !== undefined && output.message !== null ? output.message : undefined
  };
};
var deserializeAws_json1_1RoleMapping = function (output, context) {
  return {
    AmbiguousRoleResolution: output.AmbiguousRoleResolution !== undefined && output.AmbiguousRoleResolution !== null ? output.AmbiguousRoleResolution : undefined,
    RulesConfiguration: output.RulesConfiguration !== undefined && output.RulesConfiguration !== null ? deserializeAws_json1_1RulesConfigurationType(output.RulesConfiguration, context) : undefined,
    Type: output.Type !== undefined && output.Type !== null ? output.Type : undefined
  };
};
var deserializeAws_json1_1RoleMappingMap = function (output, context) {
  return Object.entries(output).reduce(function (acc, _a) {
    var _b;
    var _c = __read(_a, 2),
      key = _c[0],
      value = _c[1];
    if (value === null) {
      return acc;
    }
    return __assign(__assign({}, acc), (_b = {}, _b[key] = deserializeAws_json1_1RoleMapping(value, context), _b));
  }, {});
};
var deserializeAws_json1_1RolesMap = function (output, context) {
  return Object.entries(output).reduce(function (acc, _a) {
    var _b;
    var _c = __read(_a, 2),
      key = _c[0],
      value = _c[1];
    if (value === null) {
      return acc;
    }
    return __assign(__assign({}, acc), (_b = {}, _b[key] = value, _b));
  }, {});
};
var deserializeAws_json1_1RulesConfigurationType = function (output, context) {
  return {
    Rules: output.Rules !== undefined && output.Rules !== null ? deserializeAws_json1_1MappingRulesList(output.Rules, context) : undefined
  };
};
var deserializeAws_json1_1SAMLProviderList = function (output, context) {
  return (output || []).filter(function (e) {
    return e != null;
  }).map(function (entry) {
    if (entry === null) {
      return null;
    }
    return entry;
  });
};
var deserializeAws_json1_1TagResourceResponse = function (output, context) {
  return {};
};
var deserializeAws_json1_1TooManyRequestsException = function (output, context) {
  return {
    message: output.message !== undefined && output.message !== null ? output.message : undefined
  };
};
var deserializeAws_json1_1UnprocessedIdentityId = function (output, context) {
  return {
    ErrorCode: output.ErrorCode !== undefined && output.ErrorCode !== null ? output.ErrorCode : undefined,
    IdentityId: output.IdentityId !== undefined && output.IdentityId !== null ? output.IdentityId : undefined
  };
};
var deserializeAws_json1_1UnprocessedIdentityIdList = function (output, context) {
  return (output || []).filter(function (e) {
    return e != null;
  }).map(function (entry) {
    if (entry === null) {
      return null;
    }
    return deserializeAws_json1_1UnprocessedIdentityId(entry, context);
  });
};
var deserializeAws_json1_1UntagResourceResponse = function (output, context) {
  return {};
};
var deserializeMetadata = function (output) {
  var _a;
  return {
    httpStatusCode: output.statusCode,
    requestId: (_a = output.headers["x-amzn-requestid"]) !== null && _a !== void 0 ? _a : output.headers["x-amzn-request-id"],
    extendedRequestId: output.headers["x-amz-id-2"],
    cfId: output.headers["x-amz-cf-id"]
  };
};
// Collect low-level response body stream to Uint8Array.
var collectBody = function (streamBody, context) {
  if (streamBody === void 0) {
    streamBody = new Uint8Array();
  }
  if (streamBody instanceof Uint8Array) {
    return Promise.resolve(streamBody);
  }
  return context.streamCollector(streamBody) || Promise.resolve(new Uint8Array());
};
// Encode Uint8Array data into string with utf-8.
var collectBodyString = function (streamBody, context) {
  return collectBody(streamBody, context).then(function (body) {
    return context.utf8Encoder(body);
  });
};
var buildHttpRpcRequest = function (context, headers, path, resolvedHostname, body) {
  return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, contents;
    return __generator(this, function (_c) {
      switch (_c.label) {
        case 0:
          return [4 /*yield*/, context.endpoint()];
        case 1:
          _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port;
          contents = {
            protocol: protocol,
            hostname: hostname,
            port: port,
            method: "POST",
            path: path,
            headers: headers
          };
          if (resolvedHostname !== undefined) {
            contents.hostname = resolvedHostname;
          }
          if (body !== undefined) {
            contents.body = body;
          }
          return [2 /*return*/, new __HttpRequest(contents)];
      }
    });
  });
};
var parseBody = function (streamBody, context) {
  return collectBodyString(streamBody, context).then(function (encoded) {
    if (encoded.length) {
      return JSON.parse(encoded);
    }
    return {};
  });
};
/**
 * Load an error code for the aws.rest-json-1.1 protocol.
 */
var loadRestJsonErrorCode = function (output, data) {
  var findKey = function (object, key) {
    return Object.keys(object).find(function (k) {
      return k.toLowerCase() === key.toLowerCase();
    });
  };
  var sanitizeErrorCode = function (rawValue) {
    var cleanValue = rawValue;
    if (cleanValue.indexOf(":") >= 0) {
      cleanValue = cleanValue.split(":")[0];
    }
    if (cleanValue.indexOf("#") >= 0) {
      cleanValue = cleanValue.split("#")[1];
    }
    return cleanValue;
  };
  var headerKey = findKey(output.headers, "x-amzn-errortype");
  if (headerKey !== undefined) {
    return sanitizeErrorCode(output.headers[headerKey]);
  }
  if (data.code !== undefined) {
    return sanitizeErrorCode(data.code);
  }
  if (data["__type"] !== undefined) {
    return sanitizeErrorCode(data["__type"]);
  }
  return "";
};
