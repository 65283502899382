import { __awaiter, __generator, __read, __spread } from "tslib";
export var retryMiddleware = function (options) {
  return function (next, context) {
    return function (args) {
      return __awaiter(void 0, void 0, void 0, function () {
        var _a;
        return __generator(this, function (_b) {
          if ((_a = options === null || options === void 0 ? void 0 : options.retryStrategy) === null || _a === void 0 ? void 0 : _a.mode) context.userAgent = __spread(context.userAgent || [], [["cfg/retry-mode", options.retryStrategy.mode]]);
          return [2 /*return*/, options.retryStrategy.retry(next, args)];
        });
      });
    };
  };
};
export var retryMiddlewareOptions = {
  name: "retryMiddleware",
  tags: ["RETRY"],
  step: "finalizeRequest",
  priority: "high",
  override: true
};
export var getRetryPlugin = function (options) {
  return {
    applyToStack: function (clientStack) {
      clientStack.add(retryMiddleware(options), retryMiddlewareOptions);
    }
  };
};
