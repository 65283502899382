import { __assign, __awaiter, __generator, __read, __spread } from "tslib";
import { HttpRequest } from "@aws-sdk/protocol-http";
import { SPACE, UA_ESCAPE_REGEX, USER_AGENT, X_AMZ_USER_AGENT } from "./constants";
/**
 * Build user agent header sections from:
 * 1. runtime-specific default user agent provider;
 * 2. custom user agent from `customUserAgent` client config;
 * 3. handler execution context set by internal SDK components;
 * The built user agent will be set to `x-amz-user-agent` header for ALL the
 * runtimes.
 * Please note that any override to the `user-agent` or `x-amz-user-agent` header
 * in the HTTP request is discouraged. Please use `customUserAgent` client
 * config or middleware setting the `userAgent` context to generate desired user
 * agent.
 */
export var userAgentMiddleware = function (options) {
  return function (next, context) {
    return function (args) {
      return __awaiter(void 0, void 0, void 0, function () {
        var request, headers, userAgent, defaultUserAgent, customUserAgent, normalUAValue;
        var _a, _b;
        return __generator(this, function (_c) {
          switch (_c.label) {
            case 0:
              request = args.request;
              if (!HttpRequest.isInstance(request)) return [2 /*return*/, next(args)];
              headers = request.headers;
              userAgent = ((_a = context === null || context === void 0 ? void 0 : context.userAgent) === null || _a === void 0 ? void 0 : _a.map(escapeUserAgent)) || [];
              return [4 /*yield*/, options.defaultUserAgentProvider()];
            case 1:
              defaultUserAgent = _c.sent().map(escapeUserAgent);
              customUserAgent = ((_b = options === null || options === void 0 ? void 0 : options.customUserAgent) === null || _b === void 0 ? void 0 : _b.map(escapeUserAgent)) || [];
              // Set value to AWS-specific user agent header
              headers[X_AMZ_USER_AGENT] = __spread(defaultUserAgent, userAgent, customUserAgent).join(SPACE);
              normalUAValue = __spread(defaultUserAgent.filter(function (section) {
                return section.startsWith("aws-sdk-");
              }), customUserAgent).join(SPACE);
              if (options.runtime !== "browser" && normalUAValue) {
                headers[USER_AGENT] = headers[USER_AGENT] ? headers[USER_AGENT] + " " + normalUAValue : normalUAValue;
              }
              return [2 /*return*/, next(__assign(__assign({}, args), {
                request: request
              }))];
          }
        });
      });
    };
  };
};
/**
 * Escape the each pair according to https://tools.ietf.org/html/rfc5234 and join the pair with pattern `name/version`.
 * User agent name may include prefix like `md/`, `api/`, `os/` etc., we should not escape the `/` after the prefix.
 * @private
 */
var escapeUserAgent = function (_a) {
  var _b = __read(_a, 2),
    name = _b[0],
    version = _b[1];
  var prefixSeparatorIndex = name.indexOf("/");
  var prefix = name.substring(0, prefixSeparatorIndex); // If no prefix, prefix is just ""
  var uaName = name.substring(prefixSeparatorIndex + 1);
  if (prefix === "api") {
    uaName = uaName.toLowerCase();
  }
  return [prefix, uaName, version].filter(function (item) {
    return item && item.length > 0;
  }).map(function (item) {
    return item === null || item === void 0 ? void 0 : item.replace(UA_ESCAPE_REGEX, "_");
  }).join("/");
};
export var getUserAgentMiddlewareOptions = {
  name: "getUserAgentMiddleware",
  step: "build",
  priority: "low",
  tags: ["SET_USER_AGENT", "USER_AGENT"],
  override: true
};
export var getUserAgentPlugin = function (config) {
  return {
    applyToStack: function (clientStack) {
      clientStack.add(userAgentMiddleware(config), getUserAgentMiddlewareOptions);
    }
  };
};
